export const buttonNameConstants = {
  NO: "No",
  YES: "Yes",
  ADD: "Add",
  PAY: "Pay",
  SAVE: "Save",
  CASH: "Cash",
  TEXT: "Text",
  BACK: "Back",
  EDIT: "Edit",
  RESET: "Reset",
  TOTAL: "Total",
  EMAIL: "Email",
  LOGIN: "Login",
  CLEAR: "Clear",
  PRINT: "Print",
  APPLY: "Apply",
  VALUE: "Value",
  CLOSE: "Close",
  SUBMIT: "Submit",
  SEARCH: "Search",
  MANAGE: "Manage",
  CANCEL: "Cancel",
  DELETE: "Delete",
  UPDATE: "Update",
  SCAN_ID: "Scan ID",
  BROWSER: "Browse",
  DISMISS: "Dismiss",
  PRINTER: "Printer",
  PERCENT: "Percent",
  NEW_SALE: "New Sale",
  ADD_TYPE: "Add Type",
  COMPLETE: "Complete",
  CONTINUE: "Continue",
  TRANSFER: "Transfer",
  SUBSCRIBE: "Subscribe",
  PRINT_ALL: "Print All",
  NEW_TRADE: "New Trade",
  ADD_BATCH: "Add Batch",
  PARK_SALE: "Park Sale",
  SAVE_BATCH: "Save Batch",
  CHANGE_PIN: "Change Pin",
  EXPORT_CSV: "Export CSV",
  IMPORT_CSV: "Import CSV",
  EBAY_SALES: "eBay Sales",
  PARK_TRADE: "Park Trade",
  BULK_UPDATE: "Bulk Update",
  DISMISS_ALL: "Dismiss All",
  ADD_TO_CART: "Add to Cart",
  CREDIT_CARD: "Credit Card",
  PLEASE_WAIT: "Please Wait",
  REPORT_A_BUG: "Report a Bug",
  ITEM_HISTORY: "Item History",
  ACTIVITY_LOG: "Activity Log",
  ISSUE_REFUND: "Issue Refund",
  START_IMPORT: "Start Import",
  IMPORT_USERS: "Import Users",
  GIFT_RECEIPT: "Gift Receipt",
  RETRIVE_SALE: "Retrive Sale",
  ADD_CATEGORY: "Add Category",
  CHANGE_PRICE: "Change Price",
  ADD_CUSTOMER: "Add Customer",
  STORE_CREDIT: "Store Credit",
  TEST_PRINTER: "Test Printer",
  CHECK_STATUS: "Check Status",
  VIEW_DETAILS: "View Details",
  PRINT_LABEL: "Print Label(s)",
  ADD_INVENTORY: "Add Inventory",
  GIFT_CARD_PAYMENT: "Gift Card",
  CUSTOM_ITEM: "Add Custom Item",
  PRINT_RECEIPT: "Print Receipt",
  RETRIVE_TRADE: "Retrieve Trade",
  REPORT_PROBLEM: "Report Problem",
  CONTINUE_BATCH: "Continue Batch",
  QUICK_TRADE_ID: "Quick Trade-In",
  PRICE_CHARTING: "Price Charting",
  DASHBOARD: "Return To Dashboard",
  GIFT_CARD: "Sell/Load Gift Card",
  IMPORT_CUSTOMER: "Import Customers",
  EXPORT_CUSTOMER: "Export Customers",
  ADD_NEW_PRINTER: "Add new Printer",
  CHANGE_PASSWORD: "Change Password",
  VIEW_EBAY_SALES: "View eBay Sales",
  FILTER_SETTINGS: "Filter Settings",
  MARK_ALL_AS_READ: "Mark all as read",
  ADD_TO_INVENTORY: "Add to Inventory",
  PRINT_TEST_LABEL: "Print Test Label",
  ADD_SUB_CATEGORY: "Add Sub-Category",
  TEST_CASH_DRWAER: "Test Cash Drawer",
  VIEW_TCG_PLAYER: "View TCGplayer Page",
  PRINT_TEST_RECEIPT: "Print Test Receipt",
  VIEW_PRICE_CHARTING: "View Pricecharting",
  SET_DEFAULT_PRINTER: "Set Default Printer",
  CANCEL_SUBSCRIPTION: "Cancel Subscription",
  TEST_DEFAULT_PRINTER: "Test Default Printer",
  PRINT_PRODUCT_LABEL: "Print Label(s)",
  CANCEL_THE_TRANSACTION: "Cancel the Transaction",
  RESET_CUSTOMER_CREDIT: "Reset Customer’s Store Credit",
  TRANSFER_CUSTOMER_CREDIT: "Transfer Customer’s Store Credit",
};

export const buttonTypeConstants = {
  GREEN_BUTTON: "GREEN_BUTTON",
  RED_BUTTON: "RED BUTTON",
  BLUE_BUTTON: "BLUE BUTTON",
  GHOST_BUTTON: "GHOST BUTTON",
  LIGHT_PURPLE: "LIGHT PURPLE",
  ACTION_BUTTON: "ACTION BUTTON",
  GHOST_BUTTON_2: "GHOST BUTTON 2",
  TOP_BAR_TOGGLE_BUTTON: "TOP_BAR_TOGGLE_BUTTON",
  TRANSPARENT_BUTTON_GHOST: "TRANSPARENT_BUTTON_GHOST",
  TRANSPARENT_BUTTON_PURPLE: "TRANSPARENT_BUTTON_PURPLE",
};
