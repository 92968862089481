import { Form, Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import { Modal, Row, Col } from "react-bootstrap";

import { inventoryService } from "../../../../services";
import Button from "../../../../shared/components/Buttons/Button";
import {
  customToast,
  formatDate,
  handleCashOfferValues,
  handleTradeOfferValues,
  parseToDecimalNumber,
} from "../../../../shared/utility";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import {
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  toastType,
  transactionConstants,
  inventoryConstants,
} from "../../../../constants";
import { systemActions, transactionActions } from "../../../../redux/actions";
import { addQuickTradeInValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import { DollarIcon } from "../../../../assets/icons/iconsProvider";
import { PRODUCT_TYPES_ENUMS } from "../../../../system/globalEnums";

const AddQuickTradeIn = (props) => {
  const {
    transaction,
    newTradeData,
    currentStore,
    productToEdit,
    addItemInCart,
    tradeDiscount,
    activateSpinner,
    generateTempSku,
    handleTradeData,
    deactivateSpinner,
    quickTradeInModal,
    toggleQuickTradeInModal,
  } = props;
  const { tempSku } = transaction;

  const isProductEdit = productToEdit?.id;

  //-------preset prices
  const { tradeinMargin } = tradeDiscount;
  const globalTradeinMargin = tradeinMargin?.globalMargin;
  const ratio =
    globalTradeinMargin?.tradeinMarginPercentage /
    globalTradeinMargin?.cashMarginPercentage;
  const initialValues = {
    // productType: inventoryConstants.CUSTOM_PRODUCT_TYPES[0].value,
    cashOffer: isProductEdit
      ? parseToDecimalNumber(productToEdit.cashOffer)
      : globalConstants.EMPTY_STRING,
    tradeOffer: isProductEdit
      ? parseToDecimalNumber(productToEdit.tradeOffer)
      : globalConstants.EMPTY_STRING,
    totalItems: isProductEdit
      ? productToEdit?.price?.totalItems
      : globalConstants.EMPTY_STRING,
  };

  //-------handle hide modal
  const handleHideModal = () => {
    toggleQuickTradeInModal();
  };

  const handleQuickTradeInSubmit = async (values) => {
    const valuesObject = {
      upc: "",
      tags: [],
      imgUrl: "",
      cardRarity: "",
      cardNumber: "",
      genre: "",
      trackQuantity: true,
      additionalCheckList: [],
      date_added: formatDate(new Date()),
      epid: globalConstants.EMPTY_STRING,
      productType: PRODUCT_TYPES_ENUMS.OTHER,
      apiSource: globalConstants.EMPTY_STRING,
      description: globalConstants.EMPTY_STRING,
      subcategory: globalConstants.EMPTY_STRING,
      tcgPlayerUrl: globalConstants.EMPTY_STRING,
      serialNumber: globalConstants.EMPTY_STRING,
      product_id: transactionConstants.QUICK_TRADE,
      product_name: transactionConstants.QUICK_TRADE,
      category_name: transactionConstants.QUICK_TRADE,
      store: {
        id: currentStore?.id,
        name: currentStore?.storeName,
      },
      price: {
        quantity: 1,
        marketPrice: 0,
        unit_sell_price: 1,
        unit_purchase_price: "",
        totalItems: values.totalItems,
        type: inventoryConstants.VIDEO_GAME_CONDITION[1].value,
      },
    };

    activateSpinner(transactionConstants.ADD_CUSTOM_ITEM);

    let miscellaneousItem = {
      ...valuesObject,
      max: 9999,
      sku: [`${transactionConstants.QUICK_TRADE} ${tempSku}`],
      cashOffer: values.cashOffer,
      tradeOffer: values.tradeOffer,
      ratio: ratio,
      tradeProductMetaData: {
        isItemLocked: false,
        cashPercentagePerItem: 0,
        tradePercentagePerItem: 0,
        tradeProductType: transactionConstants.QUICK_TRADE_PRODUCT,
        tradeInMarginTypeObject: {
          marginType: {
            value: inventoryConstants.MARGIN_TYPE_GLOBAL,
            label: inventoryConstants.MARGIN_TYPE_GLOBAL,
          },
          marginObject: tradeinMargin.globalMargin,
        },
      },
    };

    //------Keep an initial State of the Item
    miscellaneousItem = {
      ...miscellaneousItem,
      productInitialState: miscellaneousItem,
    };

    if (productToEdit?.id) {
      handleTradeData({
        ...newTradeData,
        inventory: newTradeData.inventory.map((item) => {
          return item.id === productToEdit.id
            ? {
                ...productToEdit,
                ...miscellaneousItem,
                productInitialState: productToEdit.productInitialState,
              }
            : item;
        }),
      });
    } else {
      addItemInCart(miscellaneousItem);
    }

    handleHideModal();
    deactivateSpinner(transactionConstants.ADD_CUSTOM_ITEM);
  };

  return (
    <Modal
      show={quickTradeInModal}
      size="md"
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            {isProductEdit ? "Edit Quick Trade-In" : "Quick Trade-In"}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body ">
        <Formik
          initialValues={initialValues}
          onSubmit={handleQuickTradeInSubmit}
          validate={addQuickTradeInValidationSchema}
        >
          {({ resetForm, setFieldValue }) => (
            <Form>
              <Row className="m-0">
                <div className="w-100 mb-3 d-flex justify-content-between align-items-center add-inventory-price-heading">
                  <span className="addTrade-gray-text">Total Items: </span>
                  <InputNumberField
                    name="totalItems"
                    placeHolder="0"
                    className="add-inventory-price-field "
                    isConvertToDecimal={false}
                    step={"1"}
                    isHandleKeyDown={true}
                  />
                </div>
                <Col xs={12} className="d-flex flex-column mt-3 mb-3 gap-3">
                  <div className="add-inventory-stock-price-wrapper">
                    <div className="w-100 mb-3 d-flex justify-content-between align-items-center add-inventory-price-heading">
                      <span className="addTrade-gray-text">Cash Offer: </span>
                      <InputNumberField
                        name="cashOffer"
                        placeHolder="0"
                        className="add-inventory-price-field input-background"
                        IconImage={DollarIcon}
                        handleChange={(e) =>
                          handleCashOfferValues(e, setFieldValue, ratio)
                        }
                        isConvertToDecimal={true}
                      />
                    </div>
                    <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                      <span className="addTrade-gray-text">Trade Offer: </span>
                      <InputNumberField
                        name="tradeOffer"
                        placeHolder="0"
                        className="add-inventory-price-field input-background "
                        IconImage={DollarIcon}
                        handleChange={(e) =>
                          handleTradeOfferValues(e, setFieldValue, ratio)
                        }
                        isConvertToDecimal={true}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} className="mb-3 d-flex gap-2">
                  <Button
                    type="button"
                    label={buttonNameConstants.CANCEL}
                    handleClick={() => {
                      resetForm();
                      handleHideModal();
                    }}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    className="w-100"
                  ></Button>
                  <Button
                    type="submit"
                    className="w-100"
                    label={
                      isProductEdit
                        ? buttonNameConstants.SAVE
                        : buttonNameConstants.ADD
                    }
                  ></Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  transaction: state.transaction,
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  generateTempSku: () => dispatch(transactionActions.generateTempSku()),
});

//-------Export transaction Component
export default connect(mapStateToProps, mapDispatchToProps)(AddQuickTradeIn);
