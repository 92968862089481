import React from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import Button from "../../../shared/components/Buttons/Button";
import InputTextField from "../../../shared/components/inputTextField/InputTextField";
import { reportValidationSchema } from "../../../shared/validationSchema/validationSchema";
import {
  buttonNameConstants,
  buttonTypeConstants,
  CONSOLE_LOGS_TYPES,
  EMPTY_STRING,
  LOGS_TYPES,
  routesPathConstants,
  toastType,
} from "../../../constants";
import { logErrorsService } from "../../../services";
import { transactionActions } from "../../../redux/actions";
import { useLogs } from "../../../shared/hooks/logsContext";
import { EMAIL_MESSAGE_TYPES } from "../../../system/globalEnums";
import collectPosLogo from "../../../assets/images/collectPosLogo.svg";
import { customToast, handleEmailOrSMSMessage } from "../../../shared/utility";
import InputTextArea from "../../../shared/components/inputTextArea/InputTextArea";

const ReportProblem = (props) => {
  const { user, currentStore } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const initialValues = {
    fullname: `${user?.firstName || EMPTY_STRING} ${
      user?.lastName || EMPTY_STRING
    }`,
    email: user?.email || EMPTY_STRING,
    problem: "",
    errorScreenUrl:
      location.state?.errorScreenUrl || routesPathConstants.DEFAULT_PATH,
    store: {
      id: currentStore?.id,
      name: currentStore?.storeName,
    },
  };

  const { logs } = useLogs();

  const handleSubmit = async (data) => {
    try {
      let consoleErrors = logs.filter(
        (console) => console.type === CONSOLE_LOGS_TYPES.ERROR
      );
      consoleErrors = consoleErrors.map((error) => error.message);

      const logData = {
        type: LOGS_TYPES.CLIENT_REPORTED_LOGS,
        errors: consoleErrors.toString() || "No Console Error",
        metaData: `Store Name: ${currentStore?.storeName}, User Email: ${user.email}, Error Screen URL: ${window.location.href}`,
      };
      const response = await logErrorsService.logErrors(logData);

      handleEmailOrSMSMessage(
        EMAIL_MESSAGE_TYPES.USER_REPORTED_ISSUE,
        false,
        {
          ...data,
          problem: `Reported Issue Id : ${response.issueId}, Error Description : ${data.problem}`,
        },
        {},
        EMPTY_STRING,
        EMPTY_STRING,
        EMPTY_STRING,
        props.sendEmailOrSMS
      );
    } catch (error) {
      customToast(error, toastType.ERROR);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={reportValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form className="login-form-wrapper">
          <Row className="w-100 m-0 d-flex justify-content-center align-items-center vw-100 vh-100">
            <Col md={6}>
              <Row>
                <Col
                  xs={12}
                  className="p-0 mb-3 d-flex flex-column justify-content-center align-items-center gap-2"
                >
                  <img src={collectPosLogo} width="40"></img>
                  <h2>Report a Bug</h2>
                </Col>
                <Col xs={12} className="p-0 mb-3">
                  <InputTextField
                    type="text"
                    name="fullname"
                    label="Name"
                    placeHolder="Enter your name"
                  />
                </Col>
                <Col xs={12} className="p-0 mb-3">
                  <InputTextField
                    type="email"
                    name="email"
                    label="Email"
                    placeHolder="Enter your Email Address"
                  />
                </Col>
                <Col xs={12} className="p-0 mb-3">
                  <InputTextArea
                    type="text"
                    name="problem"
                    label="Error Description"
                    maxLength={5000}
                    placeHolder="Please describe the steps that led to the error."
                  />
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                  <span>{values.problem.length}/5000</span>
                </Col>
                <Col xs={12} className="p-0 mt-3 d-flex gap-2">
                  <Button
                    type="button"
                    className="w-100"
                    label={buttonNameConstants.BACK}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    handleClick={() => {
                      navigate(routesPathConstants.DEFAULT_PATH);
                    }}
                  />
                  <Button
                    type="submit"
                    className="w-100"
                    label={buttonNameConstants.SUBMIT}

                    //   isDisabled={isSpinnerEnabled(
                    //     props.spinnerArray,
                    //     authenticationConstants.USER_LOGIN
                    //   )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  user: state.authentication.user,
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  sendEmailOrSMS: (data, messageType) =>
    dispatch(transactionActions.sendEmailOrSMS(data, messageType)),
});

//-------Export report Component
export default connect(mapStateToProps, mapDispatchToProps)(ReportProblem);
