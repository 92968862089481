import { connect } from "react-redux";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";

import Button from "../../../../shared/components/Buttons/Button";
import ProductImage from "../../../../assets/images/productimage.png";
import { inventoryActions, systemActions } from "../../../../redux/actions";
import ProductTagList from "../../../../shared/components/tags/ProductTagList";
import CheckboxField from "../../../../shared/components/checkBox/CheckboxField";
import SearchInventory from "../../../Inventory/Items/SearchInventory/SearchInventory";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import CategoriesDropdown from "../../../../shared/components/selectDropdown/CategoriesDropdown";
import { addNewTradeValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import {
  PRODUCT_TYPES_ENUMS,
  PRODUCT_CONDITIONS_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../../system/globalEnums";
import {
  formatDate,
  customToast,
  convertToUsd,
  getShortestSku,
  isSystemProduct,
  isMarketPriceHigh,
  onChangeStockPrice,
  handleMarginChange,
  handleViewEbaySales,
  handleViewTCGPlayer,
  getPresetStockPrice,
  parseToDecimalNumber,
  calculateOfferPrices,
  isSerialNumberUnique,
  getProductSubCategory,
  changePriceBaseOnTags,
  handleConditionChange,
  isProductsExistInStock,
  handleTradeOfferValues,
  handleCashOfferValues,
  getMarginDropdownValue,
  handleViewPriceCharting,
  handleUploadProductImage,
  handleProductConditionUpdate,
  batchPricechartingProductImage,
  getTotalNumberOfInStockProducts,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";
import {
  toastType,
  EMPTY_STRING,
  toastMessages,
  globalConstants,
  inventoryConstants,
  buttonNameConstants,
  buttonTypeConstants,
  transactionConstants,
  categoryDropdownConstants,
  toggleSwitchButtonConstants,
  TRADE_PRODUCT_DEFAULT_DATA_OBJECT,
} from "../../../../constants";
import {
  DollarIcon,
  SidebarToggleIcon,
  LogoImageReplaceIcon,
} from "../../../../assets/icons/iconsProvider";

const AddTrade = (props) => {
  //-------Destructure props
  const {
    inventory,
    addToCart,
    customTypes,
    currentStore,
    newTradeData,
    tradeDiscount,
    productToEdit,
    addTradeModal,
    isAddModalOpen,
    toggleAddModal,
    activateSpinner,
    handleTradeData,
    customCategories,
    deactivateSpinner,
    toggleAddTradeModal,
    addTradeBatchInventory,
    getInventoryByPricechartingId,
    emptyPriceChartingProductObject,
  } = props;
  //-------inventory reducer
  const { productTags, inventoryProducts, priceChartingProductInfo } =
    inventory;

  //------UseRef
  const hiddenFileInput = useRef(null);

  const isProductEdit = productToEdit?.id;

  //-------useState
  const [productUrl, setProductUrl] = useState("");
  const [initialRatio, setInitialRatio] = useState("");
  const [selectedTags, setSelectedTags] = useState(
    isProductEdit ? productToEdit?.tags : []
  );
  const [conditionList, setConditionList] = useState([]);
  const [cashOfferValue, setCashOfferValue] = useState("");
  const [productCondition, setProductCondition] = useState();
  const [productTagsList, setProductTagsList] = useState([]);
  const [marginTypeObject, setMarginTypeObject] = useState(
    isProductEdit
      ? productToEdit?.tradeProductMetaData?.tradeInMarginTypeObject
      : ""
  );
  const [tradeOfferValue, setTradeOfferValue] = useState("");
  const [productType, setProductType] = useState(PRODUCT_TYPES_ENUMS.ALL);

  const [productDataObject, setProductDataObject] = useState(
    TRADE_PRODUCT_DEFAULT_DATA_OBJECT
  );
  const [rarities, setRarities] = useState(globalConstants.EMPTY_STRING);
  const [selectedRarity, setSelectedRarity] = useState(
    globalConstants.EMPTY_STRING
  );

  const [isFirstRender, setIsFirstRender] = useState(
    isProductEdit ? true : false
  );

  //-------useref
  const asyncSelectRef = useRef();
  //-------preset prices
  const { markupPresets, tradeinMargin } = tradeDiscount;
  //-------initial values
  const initialValues = {
    quantity: 1,
    box:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE &&
      selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.WITH_BOX)
        ? true
        : false,
    manual:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE
        ? selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL)
          ? false
          : true
        : productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE
        ? selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.WITH_MANUAL)
          ? true
          : false
        : false,
    cables:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE
        ? selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.MISSING_WIRES)
          ? false
          : true
        : false,
    controller:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE
        ? selectedTags?.includes(
            inventoryConstants.PRODUCT_TAGS.MISSING_CONTROLLER
          )
          ? false
          : true
        : false,
    costOfGoods: productDataObject.costOfGoods || globalConstants.EMPTY_STRING,
    inStockPrice: Number(productDataObject.inStockPrice || 0),
    cashOffer: cashOfferValue || globalConstants.EMPTY_STRING,
    tradeOffer: tradeOfferValue || globalConstants.EMPTY_STRING,
    serialNumber: productDataObject?.serialNumber || EMPTY_STRING,
  };

  //-------Change Logo image button
  const handleReplaceButtonClick = () => {
    hiddenFileInput.current.click();
  };

  const getMarketPrice = () => {
    if (selectedRarity) {
      return Number(productDataObject?.marketPrice ?? 0);
    } else {
      return Number(convertToUsd(productDataObject.marketPrice));
    }
  };

  //------match tags of a poduct
  const isTagsEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  };

  //-------onselectedtagchange
  const onSelectTagsChange = (selectedTags) => {
    const selectedTagList = selectedTags.map((tag) => tag.label);
    changePriceBaseOnTags(
      selectedTagList,
      productDataObject,
      setProductDataObject,
      priceChartingProductInfo,
      inventoryConstants,
      PRODUCT_CONDITIONS_ENUMS,
      TRADING_CARD_CATEGORIES_ENUMS,
      productCondition,
      productType
    );
    setSelectedTags(selectedTagList);
  };

  //-------handle inventory submit
  const handleAddTradeSubmit = async (values) => {
    const batchProducts = addTradeBatchInventory?.filter(
      (product) => product?.productId === productDataObject?.productId
    );

    const cartProducts =
      newTradeData?.inventory?.filter(
        (product) => product?.product_id === productDataObject?.productId
      ) || [];

    if (!productDataObject?.productName) {
      customToast("Please select some product", toastType.ERROR);
    } else if (
      isSystemProduct(getProductSubCategory(productDataObject?.genre)) &&
      !isSerialNumberUnique(values.serialNumber, [
        ...(inventoryProducts || []),
        ...(batchProducts || []),
        ...(cartProducts || []),
      ])
    ) {
      customToast(
        toastMessages.PRODUCT_SERIAL_NUMBER_SHOULD_BE_UNIQUE,
        toastType.ERROR
      );
      return;
    } else if (values?.serialNumber && values.quantity > 1) {
      customToast(toastMessages.SYSTEM_QUANTITY_MUST_BE_1, toastType.ERROR);
      return;
    } else {
      const skuNumber = [transactionConstants.AUTO_GENERATED];

      let dataToAdd = {
        sku: skuNumber,
        description: "",
        ratio: initialRatio,
        trackQuantity: true,
        productType: productType,
        upc: productDataObject.upc,
        cashOffer: values.cashOffer,
        epid: productDataObject.epid,
        tradeOffer: values.tradeOffer,
        genre: productDataObject.genre,
        imgUrl: productDataObject?.imgUrl,
        date_added: formatDate(new Date()),
        serialNumber: values?.serialNumber,
        tags: selectedTags?.map((tag) => tag),
        product_id: productDataObject.productId,
        cardRarity: selectedRarity?.label || "",
        additionalCheckList: ["box", "ma11nual"],
        product_name: productDataObject.productName,
        tcgPlayerUrl: productDataObject.tcgPlayerUrl,
        category_name: productDataObject.consoleName,
        cardNumber: productDataObject?.cardNumber || "",
        apiSource: priceChartingProductInfo?.productMetaData?.sourceApi,
        store: {
          id: currentStore?.id,
          name: currentStore?.storeName,
        },
        price: {
          unit_purchase_price: values.costOfGoods,
          unit_sell_price: values.inStockPrice,
          quantity: values.quantity,
          type: productCondition?.value,
          marketPrice: getMarketPrice() || 0,
        },
        subcategory:
          productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
            ? getProductSubCategory(productDataObject.genre)
            : "",
        tradeProductMetaData: {
          tradeProductType: transactionConstants.PRICECHARTING_PRODUCT,
          isItemLocked: false,
          cashPercentagePerItem: 0,
          tradePercentagePerItem: 0,
          tradeInMarginTypeObject: marginTypeObject,
        },
      };

      //------Keep an initial State of the Item
      dataToAdd = {
        ...dataToAdd,
        productInitialState: dataToAdd,
      };

      if (productToEdit?.id) {
        handleTradeData({
          ...newTradeData,
          inventory: newTradeData.inventory.map((item) => {
            return item.id === productToEdit.id
              ? {
                  ...productToEdit,
                  ...dataToAdd,
                  productInitialState: productToEdit.productInitialState,
                }
              : item;
          }),
        });
      } else {
        addToCart(dataToAdd);
      }
    }
    toggleAddTradeModal();
    toggleAddModal(false);
    clearAddInventoryForm();
  };

  useEffect(() => {
    const marketPrice = getMarketPrice();
    if (marketPrice === 0) {
      const ratio =
        marginTypeObject?.marginObject?.tradeinMarginPercentage /
        marginTypeObject?.marginObject?.cashMarginPercentage;
      setInitialRatio(ratio);
    } else {
      const initialCashValue =
        marketPrice *
        (marginTypeObject?.marginObject?.cashMarginPercentage / 100);
      const initialTradeValue =
        marketPrice *
        (marginTypeObject?.marginObject?.tradeinMarginPercentage / 100);
      const initialRatio = initialTradeValue / initialCashValue;
      setInitialRatio(initialRatio);
    }
    // eslint-disable-next-line
  }, [marginTypeObject]);

  //-------hide modal
  const handleHideModal = () => {
    toggleAddTradeModal();
    toggleAddModal(false);
    clearAddInventoryForm();
  };

  //-------empty product data object
  const emptyProductDataObject = () => {
    setProductDataObject({
      productId: globalConstants.EMPTY_STRING,
      productName: globalConstants.EMPTY_STRING,
      consoleName: globalConstants.EMPTY_STRING,
      marketPrice: 0,
      suggestedSellPrice: 0,
      costOfGoods: 0,
      inStorePrice: 0,
      inStockPrice: 0,
      inStockQuantity: 0,
      skuNumber: 0,
      gameStopBuyPrice: 0,
      genre: globalConstants.EMPTY_STRING,
      upc: globalConstants.EMPTY_STRING,
      averageBuyPrice: 0,
      averageSellPrice: 0,
      maxBuyPrice: 0,
      cardNumber: 0,
      tcgPlayerUrl: globalConstants.EMPTY_STRING,
      imgUrl: globalConstants.EMPTY_STRING,
      totalUnitsSold: 0,
      epid: globalConstants.EMPTY_STRING,
    });
  };

  //------find product details
  const findInventoryProductDetail = async (condition) => {
    if (inventoryProducts?.length > 0) {
      const findProduct = isProductsExistInStock(
        condition,
        selectedTags,
        selectedRarity,
        inventoryProducts,
        priceChartingProductInfo
      );

      setProductDataObject((prevState) => ({
        ...prevState,
        inStockQuantity: getTotalNumberOfInStockProducts(findProduct) || 0,
        skuNumber: findProduct?.length > 1 ? [] : findProduct?.[0]?.sku || [],
        inStorePrice: findProduct?.[0]?.price?.unit_sell_price || 0,
        inStockPrice:
          isProductEdit && isFirstRender
            ? productToEdit?.price?.unit_sell_price
            : findProduct?.[0]?.price?.unit_sell_price ||
              getPresetStockPrice(
                productType,
                productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME ||
                  prevState.consoleName ===
                    TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
                  ? convertToUsd(prevState.marketPrice)
                  : prevState.marketPrice,
                markupPresets,
                prevState
              ),
        costOfGoods: findProduct?.price?.unit_purchase_price || 0,
        averageBuyPrice: findProduct?.[0]?.price?.averageBuyPrice || 0,
        averageSellPrice: findProduct?.[0]?.price?.averageSellPrice || 0,
        maxBuyPrice: findProduct?.[0]?.price?.maxBuyPrice || 0,
        totalUnitsSold: findProduct?.[0]?.price?.totalUnitsSold || 0,
      }));
      return;
    }

    setProductDataObject((prevState) => ({
      ...prevState,
      inStorePrice: 0,
      inStockPrice:
        isProductEdit && isFirstRender
          ? productToEdit?.price?.unit_sell_price
          : getPresetStockPrice(
              productType,
              productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME ||
                prevState.consoleName ===
                  TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
                ? convertToUsd(prevState.marketPrice)
                : prevState.marketPrice,
              markupPresets,
              prevState
            ),
    }));
  };

  //-------clear form
  const clearAddInventoryForm = () => {
    emptyPriceChartingProductObject();
    emptyProductDataObject();
    setProductCondition("");
    setConditionList([]);
    setProductTagsList([]);
    setProductUrl("");
    setSelectedRarity("");
  };

  const handleRaritiesChange = (selectedOption) => {
    if (selectedOption) {
      setIsFirstRender(false);
      setSelectedRarity(selectedOption);
      setProductDataObject({
        ...productDataObject,
        marketPrice: selectedOption?.value?.value,
      });
    }
  };

  //-------UseEffect
  useEffect(() => {
    emptyPriceChartingProductObject(); // eslint-disable-next-line
  }, [currentStore]);

  useEffect(() => {
    clearAddInventoryForm(); // eslint-disable-next-line
  }, [isAddModalOpen]);

  //-------update product condition and type
  useEffect(() => {
    if (priceChartingProductInfo?.productObject) {
      handleProductConditionUpdate(
        currentStore,
        priceChartingProductInfo,
        getInventoryByPricechartingId,
        setProductType,
        setProductUrl,
        productDataObject,
        setProductDataObject,
        productTags,
        setProductTagsList,
        setRarities,
        setSelectedRarity,
        setConditionList,
        setProductCondition,
        false,
        isProductEdit,
        productToEdit
      );
    }
    setProductUrl(""); // eslint-disable-next-line
  }, [priceChartingProductInfo.productObject]);

  const updateProductImageUrl = async () => {
    const findProduct = isProductsExistInStock(
      productCondition?.value || "",
      selectedTags,
      selectedRarity,
      inventoryProducts,
      priceChartingProductInfo
    );

    const newImgUrl = findProduct?.length
      ? findProduct[0]?.imgUrl
      : (priceChartingProductInfo &&
          productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME) ||
        productDataObject.genre === TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
      ? await batchPricechartingProductImage(priceChartingProductInfo)
      : EMPTY_STRING;

    setProductDataObject((prevState) => ({
      ...prevState,
      imgUrl: prevState?.imgUrl || newImgUrl,
    }));
  };

  useEffect(() => {
    if (
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE
    ) {
      findInventoryProductDetail(
        isSystemProduct(getProductSubCategory(productDataObject?.genre))
          ? PRODUCT_CONDITIONS_ENUMS.LOOSE
          : PRODUCT_CONDITIONS_ENUMS.COMPLETE
      );
    } else if (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.NEAR_MINT) {
      findInventoryProductDetail(PRODUCT_CONDITIONS_ENUMS.NEAR_MINT);
    }

    updateProductImageUrl();
    // eslint-disable-next-line
  }, [inventoryProducts]);

  useEffect(() => {
    if (productCondition?.value) {
      findInventoryProductDetail(productCondition?.value);
    }
  }, [selectedTags, selectedRarity, productCondition]);

  useEffect(() => {
    const marketPrice = getMarketPrice();
    var marginDropDownObject = getMarginDropdownValue(
      getMarketPrice(),
      tradeinMargin,
      productType,
      {
        ...productDataObject,
        tags: selectedTags,
        condition: productCondition?.value,
        cardRarity: selectedRarity?.value || EMPTY_STRING,
        subcategory: getProductSubCategory(productDataObject.genre),
      }
    );
    setMarginTypeObject(
      isProductEdit
        ? productToEdit?.tradeProductMetaData?.tradeInMarginTypeObject
        : marginDropDownObject
    );

    const { cashOffer, tradeOffer } = calculateOfferPrices(
      marketPrice,
      marginDropDownObject?.marginObject,
      marginDropDownObject?.marginObject?.cashMarginPercentage,
      marginDropDownObject?.marginObject?.tradeinMarginPercentage
    );
    setTradeOfferValue(
      isProductEdit && isFirstRender
        ? productToEdit?.tradeOffer
        : parseToDecimalNumber(tradeOffer)
    );
    setCashOfferValue(
      isProductEdit && isFirstRender
        ? productToEdit?.cashOffer
        : parseToDecimalNumber(cashOffer)
    );
  }, [productDataObject]);

  return (
    <Modal
      show={addTradeModal}
      size="xl"
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className={`add-inventory-modal ${productUrl ? "iframe-opened" : ""}`}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleAddTradeSubmit}
        validate={(values) =>
          addNewTradeValidationSchema(
            values,
            currentStore?.[
              toggleSwitchButtonConstants.IS_SERIAL_NUMBER_REQUIRED_INVENTORY
            ] &&
              getProductSubCategory(productDataObject?.genre) ===
                TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
          )
        }
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Modal.Header
              closeButton
              className="add-inventory-modal-header pt-0 pb-1"
            >
              <Modal.Title>
                <span className="add-inventory-modal-header-name">
                  {isProductEdit ? "Edit Trade-In Item" : "Add Trade-In"}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="add-inventory-modal-body pt-0 d-flex ">
              <Row className={`m-0 ${productUrl ? "w-50" : "w-100"}`}>
                {!isProductEdit && (
                  <Col md={12} className=" ps-md-0">
                    <SearchInventory
                      customTypes={customTypes}
                      asyncSelectRef={asyncSelectRef}
                      customCategories={customCategories}
                    />
                  </Col>
                )}
                <Col xs={12} className="add-inventory-hero-wrapper px-0">
                  <Row className="m-0">
                    <Col md={8} className="d-flex gap-3">
                      <div className="add-inventory-product-image">
                        <img src={productDataObject?.imgUrl || ProductImage} />
                        <input
                          type="file"
                          className="d-none"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) =>
                            handleUploadProductImage(
                              e,
                              activateSpinner,
                              deactivateSpinner,
                              productDataObject,
                              setProductDataObject
                            )
                          }
                          ref={hiddenFileInput}
                        />
                        <button
                          onClick={handleReplaceButtonClick}
                          type="button"
                          className="replace-logo-button"
                        >
                          <LogoImageReplaceIcon className="replace-icon" />
                        </button>
                      </div>
                      <div className="d-flex flex-column justify-content-between align-items-start product-content-wrapper">
                        <span>
                          {productDataObject.productName || "Product Name"}
                        </span>
                        <span>
                          {productDataObject.consoleName || "Console Name"}
                        </span>
                        <span>
                          {productDataObject?.skuNumber?.length
                            ? `#${getShortestSku(productDataObject?.skuNumber)}`
                            : "#Auto Generated"}
                        </span>
                        <span>{`In Stock: ${productDataObject.inStockQuantity}`}</span>
                      </div>
                    </Col>
                    {priceChartingProductInfo?.productMetaData?.sourceApi ===
                    inventoryConstants.PRICECHARTING_API ? (
                      <Col
                        md={4}
                        className="d-flex flex-column align-items-center gap-2"
                      >
                        <Button
                          type="button"
                          label={buttonNameConstants.VIEW_EBAY_SALES}
                          className="w-100"
                          handleClick={() =>
                            handleViewEbaySales(
                              productDataObject?.productName,
                              productDataObject?.consoleName,
                              productDataObject?.genre
                            )
                          }
                          buttonType={buttonTypeConstants.LIGHT_PURPLE}
                        />
                        <Button
                          type="button"
                          label={buttonNameConstants.VIEW_PRICE_CHARTING}
                          className="w-100"
                          handleClick={() =>
                            handleViewPriceCharting(
                              priceChartingProductInfo,
                              setProductUrl
                            )
                          }
                          buttonType={buttonTypeConstants.LIGHT_PURPLE}
                        />
                      </Col>
                    ) : priceChartingProductInfo?.productMetaData?.sourceApi ===
                        inventoryConstants.SCRYFALL_API ||
                      priceChartingProductInfo?.productMetaData?.sourceApi ===
                        inventoryConstants.POKEMON_API ? (
                      <Col
                        md={4}
                        className="d-flex flex-column align-items-center gap-2"
                      >
                        <Button
                          type="button"
                          label={buttonNameConstants.VIEW_TCG_PLAYER}
                          className="w-100"
                          handleClick={() =>
                            handleViewTCGPlayer(productDataObject)
                          }
                          buttonType={buttonTypeConstants.LIGHT_PURPLE}
                          isDisabled={!productDataObject?.tcgPlayerUrl}
                        />
                      </Col>
                    ) : null}
                    <Col
                      md={12}
                      className="d-flex justify-content-start flex-wrap gap-3 mt-3 pt-2"
                    >
                      <span className="quantity-top-margin">Quantity:</span>
                      <InputNumberField
                        name="quantity"
                        placeHolder="0"
                        className="add-inventory-quantity-field input-background"
                        isConvertToDecimal={false}
                        step={"1"}
                        isHandleKeyDown={true}
                        disabled={
                          isSystemProduct(
                            getProductSubCategory(productDataObject?.genre)
                          ) &&
                          currentStore[
                            toggleSwitchButtonConstants
                              .IS_SERIAL_NUMBER_REQUIRED_INVENTORY
                          ]
                        }
                      />
                      <CategoriesDropdown
                        options={conditionList}
                        handleChange={(selectedOption) => {
                          setIsFirstRender(false);
                          handleConditionChange(
                            selectedOption,
                            setProductCondition,
                            priceChartingProductInfo,
                            productTags,
                            setProductTagsList,
                            setProductDataObject,
                            productDataObject,
                            setSelectedTags
                          );
                        }}
                        noOptionsMessage="No Condition Found"
                        placeholder="Condition"
                        SelectIconImage={
                          categoryDropdownConstants.INVENTORY_LIST_ICON
                        }
                        isClearable={false}
                        className="add-inventory-dropdowns-category condition-dropdown"
                        value={productCondition}
                      />{" "}
                      {isSystemProduct(
                        getProductSubCategory(productDataObject?.genre)
                      ) && (
                        <InputTextField
                          name="serialNumber"
                          maxLength={100}
                          placeHolder="Enter Serial Number"
                          className="border add-inventory-serial-number-field"
                        />
                      )}
                      {productDataObject?.genre ===
                        TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD ||
                      productDataObject?.genre ===
                        TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD ||
                      productToEdit?.category_name ===
                        TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD ||
                      productToEdit?.category_name ===
                        TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD ? (
                        <CategoriesDropdown
                          options={rarities || []}
                          handleChange={(selectedOption) =>
                            handleRaritiesChange(selectedOption)
                          }
                          noOptionsMessage="No Printing Found"
                          placeholder="Printings"
                          SelectIconImage={
                            categoryDropdownConstants.INVENTORY_LIST_ICON
                          }
                          isClearable={false}
                          className="add-inventory-dropdowns-category condition-dropdown"
                          value={selectedRarity}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                    {productCondition?.value ===
                      PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                    productCondition?.value ===
                      PRODUCT_CONDITIONS_ENUMS.LOOSE ? (
                      <Col
                        md={12}
                        className="d-flex align-items-center gap-3 my-2"
                      >
                        {productDataObject.genre ===
                        TRADING_CARD_CATEGORIES_ENUMS.SYSTEM ? (
                          productCondition?.value ===
                          PRODUCT_CONDITIONS_ENUMS.COMPLETE ? (
                            <>
                              <CheckboxField name="manual" label="Manual" />
                              {/* <CheckboxField name="box" label="Box" /> */}
                              <CheckboxField name="cables" label="Cables" />
                              <CheckboxField
                                name="controller"
                                label="Controller"
                              />
                            </>
                          ) : productCondition?.value ===
                            PRODUCT_CONDITIONS_ENUMS.LOOSE ? (
                            <>
                              <CheckboxField name="manual" label="Manual" />
                              <CheckboxField name="cables" label="Cables" />
                              <CheckboxField
                                name="controller"
                                label="Controller"
                              />
                            </>
                          ) : (
                            ""
                          )
                        ) : [
                            ...inventoryConstants.NINTENDO_CATEGORIES,
                            ...inventoryConstants.SKYLANDER_CATEGORIES,
                          ].includes(productDataObject.consoleName) ? (
                          ""
                        ) : (
                          <CheckboxField name="manual" label="Manual" />
                        )}
                      </Col>
                    ) : (
                      ""
                    )}

                    <Col
                      md={12}
                      className="my-2 d-flex align-items-center gap-4"
                    >
                      <ProductTagList
                        setIsFirstRender={setIsFirstRender}
                        className={"align-items-center"}
                        productDetail={{
                          tags: [
                            ...productTagsList?.map((defaultTag, index) => ({
                              id: index, // Adjusting ID to ensure it doesn't overlap
                              label: defaultTag,
                              selected: selectedTags.includes(defaultTag)
                                ? true
                                : false,
                            })),
                            ...selectedTags
                              ?.filter((tag) => !productTagsList.includes(tag))
                              .map((tag, index) => ({
                                id: index + productTagsList.length,
                                label: tag,
                                selected: true,
                              })),
                          ],
                        }}
                        productCondition={productCondition}
                        tagsList={[]}
                        suggestedArrayList={productTags}
                        onSelectTagsChange={onSelectTagsChange}
                        selectedManual={{
                          isChecked: values.manual,
                          value:
                            productCondition?.value ===
                            PRODUCT_CONDITIONS_ENUMS.COMPLETE
                              ? inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL
                              : productCondition?.value ===
                                PRODUCT_CONDITIONS_ENUMS.LOOSE
                              ? inventoryConstants.PRODUCT_TAGS.WITH_MANUAL
                              : "",
                        }}
                        selectedWires={{
                          isChecked: values.cables,
                          value:
                            productCondition?.value ===
                              PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                            productCondition?.value ===
                              PRODUCT_CONDITIONS_ENUMS.LOOSE
                              ? inventoryConstants.PRODUCT_TAGS.MISSING_WIRES
                              : "",
                        }}
                        selectedController={{
                          isChecked: values.controller,
                          value:
                            productCondition?.value ===
                              PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                            productCondition?.value ===
                              PRODUCT_CONDITIONS_ENUMS.LOOSE
                              ? inventoryConstants.PRODUCT_TAGS
                                  .MISSING_CONTROLLER
                              : "",
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} className="m-0 p-0 mt-4">
                  <Row className="m-0">
                    <Col
                      md={4}
                      className="add-inventory-prices-detail-wrapper d-flex flex-column align-items-lg-stretch gap-3"
                    >
                      <div>
                        <span>Total units Sold</span>
                        <span>{`${productDataObject.totalUnitsSold}`}</span>
                      </div>
                      <div>
                        <span>GameStop Buy Price</span>
                        <span>{`$${parseToThousandSeperatorDecimalNumber(
                          convertToUsd(productDataObject.gameStopBuyPrice || 0)
                        )}`}</span>
                      </div>
                      <div>
                        <span>Avg. Buy Price</span>
                        <span>{`$${parseToThousandSeperatorDecimalNumber(
                          productDataObject.averageBuyPrice || 0
                        )}`}</span>
                      </div>
                      <div>
                        <span>Max Buy Price</span>
                        <span>{`$${parseToThousandSeperatorDecimalNumber(
                          productDataObject.maxBuyPrice || 0
                        )}`}</span>
                      </div>
                      <div>
                        <span>Avg. Sell Price</span>
                        <span>{`$${parseToThousandSeperatorDecimalNumber(
                          productDataObject.averageSellPrice || 0
                        )}`}</span>
                      </div>
                    </Col>
                    <Col md={8}>
                      <Row className="m-0 h-100">
                        <Col xs={12} className="d-flex flex-column gap-1">
                          <div className="w-100 d-flex justify-content-between add-inventory-price-heading">
                            <span>Market Price: </span>
                            <span
                              className={
                                isMarketPriceHigh(
                                  getMarketPrice(),
                                  values.inStockPrice,
                                  productTags,
                                  selectedTags
                                ) && values.inStockPrice
                                  ? "text-danger"
                                  : ""
                              }
                            >{`$${parseToThousandSeperatorDecimalNumber(
                              getMarketPrice()
                            )}`}</span>
                          </div>
                          <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                            <span>Suggested sell price: </span>
                            <span>
                              {`$${parseToThousandSeperatorDecimalNumber(
                                convertToUsd(
                                  productDataObject.suggestedSellPrice
                                )
                              )}`}
                            </span>
                          </div>
                        </Col>

                        <Col xs={12} className="d-flex flex-column gap-3">
                          <div className="add-inventory-stock-price-wrapper">
                            <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                              <span className="addTrade-gray-text">
                                Margin{" "}
                              </span>
                            </div>
                            <div className="mt-3 mb-3 col-md-5">
                              <CategoriesDropdown
                                options={[
                                  inventoryConstants.MARGIN_TYPE_GLOBAL,
                                  inventoryConstants.MARGIN_TYPE_STANDARD,
                                ]}
                                disabled={
                                  getMarginDropdownValue(
                                    getMarketPrice(),
                                    tradeinMargin,
                                    productType,
                                    {
                                      ...productDataObject,
                                      tags: selectedTags,
                                      condition: productCondition?.value,
                                      cardRarity:
                                        selectedRarity?.value || EMPTY_STRING,
                                      subcategory: getProductSubCategory(
                                        productDataObject.genre
                                      ),
                                    }
                                  )?.marginType ===
                                  inventoryConstants.MARGIN_TYPE_GLOBAL
                                }
                                handleChange={(selectedOption) =>
                                  handleMarginChange(
                                    getMarketPrice(),
                                    productType,
                                    setFieldValue,
                                    selectedOption,
                                    tradeinMargin,
                                    productDataObject,
                                    setMarginTypeObject
                                  )
                                }
                                noOptionsMessage="No Condition Found"
                                placeholder="Global"
                                SelectIconImage={
                                  categoryDropdownConstants.INVENTORY_LIST_ICON
                                }
                                isClearable={false}
                                // defaultValue={marginTypeObject}
                                className="add-inventory-dropdowns-category condition-dropdown remove-minWidth-dropdown"
                                value={marginTypeObject?.marginType}
                              />
                            </div>
                            <div className="w-100 mb-3 d-flex justify-content-between align-items-center add-inventory-price-heading">
                              <span className="addTrade-gray-text">
                                Cash Offer:{" "}
                              </span>
                              <InputNumberField
                                name="cashOffer"
                                placeHolder="0"
                                className="add-inventory-price-field input-background"
                                IconImage={DollarIcon}
                                handleChange={(value) => {
                                  handleCashOfferValues(
                                    value,
                                    setFieldValue,
                                    initialRatio
                                  );
                                }}
                              />
                            </div>
                            <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                              <span className="addTrade-gray-text">
                                Trade Offer:{" "}
                              </span>
                              <InputNumberField
                                name="tradeOffer"
                                placeHolder="0"
                                className="add-inventory-price-field input-background "
                                IconImage={DollarIcon}
                                handleChange={(value) => {
                                  handleTradeOfferValues(
                                    value,
                                    setFieldValue,
                                    initialRatio
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} className="d-flex flex-column gap-3">
                          <div className="add-inventory-stock-price-wrapper">
                            <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                              <span className="addTrade-gray-text">
                                Stock Price{" "}
                              </span>
                            </div>
                            <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                              <span>In-Store: </span>
                              <InputNumberField
                                name="inStockPrice"
                                placeHolder="0"
                                className={`add-inventory-price-field input-background ${
                                  isMarketPriceHigh(
                                    getMarketPrice(),
                                    values.inStockPrice,
                                    productTags,
                                    selectedTags
                                  ) && values.inStockPrice
                                    ? "border-danger"
                                    : ""
                                }`}
                                IconImage={DollarIcon}
                                handleChange={(newValue) => {
                                  let marketPrice = getMarketPrice();
                                  onChangeStockPrice(
                                    newValue,
                                    setFieldValue,
                                    marketPrice,
                                    "",
                                    marginTypeObject
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12} className="d-flex align-items-end gap-2">
                          <Button
                            label={buttonNameConstants.CANCEL}
                            className="w-100"
                            type="button"
                            handleClick={() => handleHideModal()}
                            buttonType={buttonTypeConstants.GHOST_BUTTON}
                          />
                          <Button
                            label={
                              isProductEdit
                                ? buttonNameConstants.SAVE
                                : buttonNameConstants.ADD_TO_CART
                            }
                            className="w-100"
                            type="submit"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {productUrl && (
                <Row className="m-0 w-50">
                  <Col md={12}>
                    {productUrl && (
                      <iframe
                        title="Product Iframe"
                        src={productUrl}
                        className="iframe-website"
                      />
                    )}
                  </Col>
                </Row>
              )}
            </Modal.Body>
          </Form>
        )}
      </Formik>
      {productUrl && (
        <button
          type="button"
          onClick={() => {
            setProductUrl("");
          }}
          className="iframe-toogle-button"
        >
          <SidebarToggleIcon className={"iframe-toggle-icon"} />
        </button>
      )}
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  currentStore: state.store.currentStore,
  newTradeData: state.transaction.newTradeData,
  isAddModalOpen: state.inventory.isAddModalOpen,
  addTradeBatchInventory: state.transaction.addTradeBatchInventory,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  emptyPriceChartingProductObject: () =>
    dispatch(inventoryActions.emptyPriceChartingProductObject()),
  getInventoryByPricechartingId: (storeId, data) =>
    dispatch(inventoryActions.getInventoryByPricechartingId(storeId, data)),
  addInventory: (data) => dispatch(inventoryActions.addInventory(data)),
  toggleAddModal: (value) => dispatch(inventoryActions.toggleAddModal(value)),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(AddTrade);
