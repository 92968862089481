import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import React, { useEffect, useState, useRef } from "react";

import CashPayment from "./CashPayment";
import CardPayment from "./CardPayment";
import PaymentComplete from "./PaymentComplete";
import GiftCardPayment from "./GiftCardPayment";
import Topbar from "../../../../shared/components/topbar/Topbar";
import Button from "../../../../shared/components/Buttons/Button";
import AddNewCustomerModal from "../AddNewCustomer/AddNewCustomerModal";
import { STORE_CARD_PAYMENT_TYPE_ENUMS } from "../../../../system/globalEnums";
import { printReceiptData } from "../../../../shared/Printers/ReceiptPrinters/printReceipt";
import CustomerDropdown from "../../../../shared/components/selectDropdown/CustomerDropdown";
import SelectedCustomerCard from "../../../../shared/components/shared/SelectedCustomerCard";
import {
  handleOpenCashDrawer,
  prepareReceiptForPrinter,
} from "../../../../shared/Printers/ReceiptPrinters/prepareReceiptForPrinter";
import {
  deviceActions,
  systemActions,
  transactionActions,
} from "../../../../redux/actions";
import {
  customToast,
  getShortestSku,
  parseJsonObject,
  handleZeroOnBlur,
  getCountOfTotalItems,
  getSumOfPaymentHistory,
  convertToFixedPrecision,
  parseToNumber,
  parseToThousandSeperatorDecimalNumber,
  parseToDecimalNumber,
  getReceiptPrintDate,
} from "../../../../shared/utility";
import {
  toastType,
  toastMessages,
  globalConstants,
  routesPathConstants,
  buttonNameConstants,
  transactionConstants,
  transactionDefaultData,
  categoryDropdownConstants,
} from "../../../../constants";
import {
  CashIcon,
  GiftCardIcon,
  CreditcardIcon,
  StoreCreditIcon,
  CloseIcon,
} from "../../../../assets/icons/iconsProvider";

const NewTransactionPayment = (props) => {
  const {
    user,
    printer,
    customer,
    transaction,
    spinnerArray,
    currentStore,
    storeDevices,
    sendEmailOrSMS,
    getStoreDevices,
    activateSpinner,
    giftCardDetails,
    deactivateSpinner,
    handleNewParkSale,
    newSaleTransaction,
    isAddCustomerModal,
    getGiftCardDetails,
    setTransactionData,
    emptyGiftCardDetails,
    cancelCardTransaction,
    toggleAddCustomerModal,
  } = props;
  const navigate = useNavigate();
  const { transactionData } = transaction;
  const { state } = useLocation();

  //-------useRef
  let cartItemsCount = useRef(transactionData?.inventory?.length ?? 0);
  const [changeOwed, setChangeOwed] = useState(0);
  const [amountTendered, setAmountTendered] = useState(0);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [customerToEdit, setCustomerToEdit] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(
    transactionData?.customer?.id && transactionData?.customer
  );
  const [selectedCustomerFlag, setSelectedCustomerFlag] = useState(false);
  //handle amount tendered change
  const handleAmountTenderedChange = (e) => {
    const maxLength = 15;
    const amount = e;
    let owedAmount = 0;
    if (amount.length >= maxLength) {
      return;
    } else if (Number(amount) >= Number(transactionData?.currentAmountToPay)) {
      setAmountTendered(amount);
      owedAmount = parseToNumber(amount) - transactionData?.currentAmountToPay;
      setChangeOwed(parseToNumber(owedAmount));
    } else {
      setAmountTendered(amount);
      setChangeOwed(0);
    }
  };
  //-------Customer reducer
  const { allCustomers } = customer; //------printer reducer
  const { defaultReceiptPrinter } = printer;

  const defaultPaymentHistory = {
    [transactionConstants.CASH]: 0,
    [transactionConstants.CREDIT_CARD]: 0,
    [transactionConstants.GIFT_CARD]: 0,
    [transactionConstants.STORE_CREDIT]: 0,
  };

  const handleFixedAmountButtonClick = (amount) => {
    let owedAmount = 0;
    const tenderedAmount = Number(amountTendered) + amount;

    setAmountTendered(tenderedAmount.toFixed(2));
    if (tenderedAmount > transactionData?.currentAmountToPay) {
      owedAmount =
        Number(tenderedAmount).toFixed(2) -
        Number(transactionData?.currentAmountToPay).toFixed(2);
      setChangeOwed(parseToNumber(owedAmount));
    } else {
      setChangeOwed(0);
    }
  };

  //------ handle customer edit
  const handleCustomerEditClick = () => {
    const customer = allCustomers?.find(
      (customer) => customer.id === transactionData?.customer?.id
    );
    if (customer?.id) {
      setCustomerToEdit(customer);
      toggleAddCustomerModal(true);
    }
  };

  const handleReturnPayment = (history) => {
    //------if paymenttype is gift card
    const giftCard =
      history.paymentType === transactionConstants.GIFT_CARD
        ? {
            giftCardName: history.giftCardName,
            giftCardChargeAmount: history.giftCardChargeAmount,
            giftCardBarcode: history.giftCardBarcode,
            giftCardTotalBalance: history.giftCardBalance,
            remainingBalance: history.remainingBalance,
          }
        : {};

    const cashTenderedAmount =
      history?.paymentType === transactionConstants.CASH
        ? history.cashTenderedAmount
        : 0;

    const cashChangeOwed =
      history?.paymentType === transactionConstants.CASH
        ? history.cashChangeOwed
        : 0;
    const activeCardPaymentType =
      history?.paymentType === transactionConstants.CREDIT_CARD
        ? currentStore.activeCardPaymentType
        : STORE_CARD_PAYMENT_TYPE_ENUMS.NULL;

    if (
      history.paymentType === transactionConstants.CREDIT_CARD &&
      history.activeCardPaymentType === STORE_CARD_PAYMENT_TYPE_ENUMS.VERIFONE
    ) {
      setTransactionData(
        {
          ...transactionData,
          paymentDevice: history.poiId,
          paymentMethod: transactionConstants.CREDIT_CARD_PAYMENT,
        },
        currentStore?.id
      );
    }

    const dataToSend = {
      creditUsed: transactionData?.creditUsed,
      id: transactionData?.id,
      giftCard: giftCard,
      customer: transactionData?.customer || {},
      PaymentType: history.paymentType,
      note: transactionData?.note,
      TransactionType: transactionConstants.DRAFT,
      companyId: currentStore.companyId,
      SubTotal: transactionData?.subTotal,
      inventory: transactionData.inventory,
      currentAmountToPay: history.currentAmountToPay,
      discount: transactionData.discount || 0,
      isNoteField: transactionData.isNoteField,
      cashChangeOwed: parseToNumber(cashChangeOwed),
      isTaxApplied: transactionData.isTaxApplied,
      poiId: history?.poiId || globalConstants.EMPTY_STRING,
      activeCardPaymentType:
        history?.activeCardPaymentType || activeCardPaymentType,
      discountMethod: transactionData.discountMethod,
      Tax: parseToDecimalNumber(transactionData?.tax),
      creditCard: transactionData.creditCard,
      discountedValue: transactionData.discountedValue,
      isDiscountField: transactionData.isDiscountField,
      cashTenderedAmount: parseToNumber(cashTenderedAmount),
      totalAmountToPay: transactionData?.totalAmountToPay,
      paymentHistory: transactionData?.paymentHistory,
      initialTotalCogs: transactionData?.initialTotalCogs,
      initialTotalStockPrice: transactionData?.initialTotalStockPrice,
      store: {
        id: transactionData?.store?.id,
        name: transactionData?.store?.storeName || transactionData?.store?.name,
      },
    };

    newSaleTransaction(
      dataToSend,
      transactionData,
      handlePaymentMethod,
      handlePrintReceipt,
      currentStore?.isAutoPrintReceipt,
      true,
      toastMessages.TRANSACTION_CANCEL_SUCCESSFULLY,
      handleOpenCashDrawer,
      defaultReceiptPrinter
    );
  };

  const checkAmountToPay = () => {
    if (
      transactionData?.currentAmountToPay >
      convertToFixedPrecision(
        transactionData?.totalAmountToPay -
          parseToNumber(getSumOfPaymentHistory(transactionData.paymentHistory))
      )
    ) {
      customToast(toastMessages.AMOUNT_TO_PAY_LESS, toastType.ERROR);
      return true;
    } else if (transactionData?.currentAmountToPay <= 0) {
      customToast(toastMessages.AMOUNT_TO_PAY_GREATER, toastType.ERROR);
      return true;
    }
  };

  //-------add transaction transaction
  const addTransaction = async (paymentType, poiId) => {
    activateSpinner(transactionConstants.MAKE_TRANSACTION);
    const transactionId =
      transactionData?.id || (await transactionActions.getTransactionId());
    deactivateSpinner(transactionConstants.MAKE_TRANSACTION);
    setTransactionId(transactionId);

    if (transactionId) {
      //------calculate giftcard charge amount
      const giftCardChargeAmount =
        paymentType === transactionConstants.GIFT_CARD
          ? giftCardDetails.giftCardBalance >=
            transactionData?.currentAmountToPay
            ? parseToNumber(transactionData.currentAmountToPay)
            : giftCardDetails.giftCardBalance
          : 0;

      //------ Set Gift Card Payment Type
      const activeCardPaymentType =
        paymentType === transactionConstants.CREDIT_CARD
          ? currentStore.activeCardPaymentType
          : STORE_CARD_PAYMENT_TYPE_ENUMS.NULL;

      //------- Customer
      const customer = transactionData.customer
        ? transactionData.customer
        : {
            name: globalConstants.EMPTY_STRING,
            id: globalConstants.EMPTY_STRING,
          };

      //------ Transaction Type
      const TransactionType =
        parseToNumber(
          parseToNumber(
            paymentType === transactionConstants.GIFT_CARD
              ? giftCardDetails.giftCardBalance >=
                transactionData?.currentAmountToPay
                ? parseToNumber(transactionData.currentAmountToPay)
                : giftCardDetails.giftCardBalance
              : paymentType === transactionConstants.STORE_CREDIT
              ? parseToNumber(transactionData.currentAmountToPay) >
                parseToNumber(transactionData?.customer?.currentBalance)
                ? parseToNumber(transactionData?.customer?.currentBalance)
                : parseToNumber(transactionData.currentAmountToPay)
              : transactionData.currentAmountToPay
          ) +
            parseToNumber(
              getSumOfPaymentHistory(
                transactionData?.paymentHistory || defaultPaymentHistory
              )
            )
        ) === parseToNumber(transactionData?.totalAmountToPay)
          ? transactionConstants.PROCESSED
          : transactionConstants.DRAFT;

      //-------Current Amount to pay
      const currentAmountToPay =
        paymentType === transactionConstants.STORE_CREDIT
          ? parseToNumber(transactionData.currentAmountToPay) >
            parseToNumber(transactionData?.customer?.currentBalance)
            ? parseToNumber(transactionData?.customer?.currentBalance)
            : parseToNumber(transactionData.currentAmountToPay)
          : parseToNumber(transactionData.currentAmountToPay);

      //-------Gift Card Payment
      const giftCard =
        paymentType !== transactionConstants.GIFT_CARD
          ? {}
          : giftCardDetails
          ? {
              giftCardName: `Gift Card(...${giftCardDetails.giftCardBarcode?.slice(
                -4
              )})`,
              giftCardChargeAmount: giftCardChargeAmount,
              giftCardBarcode: giftCardDetails.giftCardBarcode,
              giftCardTotalBalance: giftCardDetails.giftCardBalance,
              remainingBalance:
                giftCardDetails.giftCardBalance - giftCardChargeAmount,
            }
          : globalConstants.EMPTY_OBJECT;

      //------- Prepare Data for Transaction
      const dataToSend = {
        creditUsed: 0,
        id: transactionId,
        giftCard: giftCard,
        customer: customer,
        PaymentType: paymentType,
        note: transactionData.note,
        TransactionType: TransactionType,
        companyId: currentStore.companyId,
        SubTotal: transactionData?.subTotal,
        inventory: transactionData.inventory,
        currentAmountToPay: currentAmountToPay,
        creditCard: transactionData.creditCard,
        discount: transactionData.discount || 0,
        isNoteField: transactionData.isNoteField,
        cashChangeOwed: parseToNumber(changeOwed),
        isTaxApplied: transactionData.isTaxApplied,
        poiId: poiId || globalConstants.EMPTY_STRING,
        activeCardPaymentType: activeCardPaymentType,
        discountMethod: transactionData.discountMethod,
        Tax: parseToDecimalNumber(transactionData?.tax),
        taxPercentage: currentStore?.defaultTax,
        discountedValue: transactionData.discountedValue,
        isDiscountField: transactionData.isDiscountField,
        cashTenderedAmount: parseToNumber(amountTendered),
        totalAmountToPay: transactionData?.totalAmountToPay,
        paymentHistory: transactionData?.paymentHistory,
        initialTotalCogs: transactionData?.initialTotalCogs,
        initialTotalStockPrice: transactionData?.initialTotalStockPrice,
        store: {
          id: transactionData?.store?.id,
          name:
            transactionData?.store?.storeName || transactionData?.store?.name,
        },
      };

      newSaleTransaction(
        dataToSend,
        transactionData,
        handlePaymentMethod,
        handlePrintReceipt,
        currentStore?.isAutoPrintReceipt,
        false,
        toastMessages.TRANSACTION_ADDED_SUCCESSFULLY,
        handleOpenCashDrawer,
        defaultReceiptPrinter
      );
    }
  };

  const handleAddCustomerClick = (customerToEdit = {}) => {
    setCustomerToEdit(customerToEdit);
    toggleAddCustomerModal(true);
  };

  //-------handle amount to pay change
  const handleAmountToPayChange = (value) => {
    setTransactionData(
      { ...transactionData, currentAmountToPay: value },
      currentStore?.id
    );
  };

  const handleCashPayment = () => {
    setAmountTendered(0);
    setChangeOwed(0);
    addTransaction(transactionConstants.CASH);
  };

  const handleCreditCardPayment = (value) => {
    setTransactionData(
      { ...transactionData, paymentDevice: value },
      currentStore?.id
    );
    addTransaction(transactionConstants.CREDIT_CARD, value);
  };

  const handleThirdPartyPayment = () => {
    addTransaction(transactionConstants.CREDIT_CARD);
  };

  const handleStoreCreditPayment = () => {
    addTransaction(transactionConstants.STORE_CREDIT);
  };

  const handleGiftCardPayment = () => {
    addTransaction(transactionConstants.GIFT_CARD);
  };

  const handlePaymentMethod = (paymentMethod) => {
    setTransactionData(
      { ...transactionData, paymentMethod: paymentMethod },
      currentStore?.id
    );
  };

  const handleTransactionCancel = () => {
    cancelCardTransaction(
      {
        poiId: transactionData.paymentDevice,
        transactionId: transactionId,
      },
      transactionData
    );
  };

  const handlePrintReceipt = (
    transactionData,
    isOpenDrawer = false,
    giftReceipt
  ) => {
    const printData = prepareReceiptForPrinter(
      user,
      transactionData.id,
      transactionData.paymentHistory,
      transactionData.store,
      transactionData.customer,
      transactionData.inventory,
      parseToDecimalNumber(transactionData.discount),
      transactionData.subTotal,
      transactionData.tax,
      transactionData.totalAmountToPay,
      transactionData.cashTenderedAmount,
      transactionData.cashChangeOwed,
      transactionData.giftCardPaymentHistory,
      isOpenDrawer,
      giftReceipt,
      transactionData.discountMethod,
      transactionData.discountedValue,
      getReceiptPrintDate(transactionData.createdOn)
    );
    printReceiptData(printData, defaultReceiptPrinter);
  };

  const handleParkSaleClick = async () => {
    activateSpinner(transactionConstants.MAKE_TRANSACTION);
    const transactionId =
      transactionData?.id || (await transactionActions.getTransactionId());
    deactivateSpinner(transactionConstants.MAKE_TRANSACTION);
    handleNewParkSale(
      { ...transactionData, id: transactionId },
      transactionData.store
    );
  };
  const selectedCustomerCheck = (selectedCustomerFlag) => {
    setSelectedCustomerFlag(selectedCustomerFlag);
  };

  const handleShowDraftModal = () => {
    // handle component unmounting
    cartItemsCount.current = 0;
  };

  useEffect(() => {
    if (!transactionData) {
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION);
    } // eslint-disable-next-line
  }, [transactionData]);

  useEffect(() => {
    if (allCustomers && allCustomers.length > 0 && selectedCustomerFlag) {
      setTransactionData(
        {
          ...transactionData,
          customer: {
            ...allCustomers[0],
            name: allCustomers[0].firstName + " " + allCustomers[0].lastName,
          },
        },
        currentStore?.id
      );
    }
    setSelectedCustomerFlag(false); // eslint-disable-next-line
  }, [allCustomers]);

  useEffect(() => {
    setAddCustomerModal(isAddCustomerModal);
  }, [isAddCustomerModal]);

  useEffect(() => {
    getStoreDevices(transactionData?.store?.id); // eslint-disable-next-line
  }, []);

  // // handle component unmounting
  // useEffect(() => {
  //   return () => {
  //     if (cartItemsCount.current > 0) {
  //       handleDraftModal(transactionConstants.TRANSACTION_DRAFT_MODAL);
  //     }
  //   }; // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (
      transactionData?.paymentMethod === transactionConstants.PAYMENT_COMPLETE
    ) {
      handleShowDraftModal();
    } // eslint-disable-next-line
  }, [transactionData.paymentMethod]);

  useEffect(() => {
    if (state.currentStore.id !== currentStore.id) {
      handleShowDraftModal();
      setTransactionData(transactionDefaultData, currentStore?.id);
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION);
    } // eslint-disable-next-line
  }, [currentStore]);

  useEffect(() => {
    if (transactionData.inventory.length <= 0) {
      handleShowDraftModal();
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION);
    } // eslint-disable-next-line
  }, [transactionData]);

  return (
    <>
      <Topbar
        title="Payment"
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION,
          navigationMessage:
            getSumOfPaymentHistory(transactionData.paymentHistory) > 0 &&
            transactionData.paymentMethod !==
              transactionConstants.PAYMENT_COMPLETE
              ? toastMessages.CLEAR_PARTIAL_PAYMENT
              : "",
        }}
        isNewSalePayment={true}
        totalCartItems={
          getCountOfTotalItems(transactionData?.inventory || 0, "quantity") || 0
        }
        handleParkSale={handleParkSaleClick}
        isTransactionComplete={
          transactionData?.paymentMethod ===
          transactionConstants.PAYMENT_COMPLETE
            ? true
            : false
        }
        setTransactionData={setTransactionData}
        showDraftModal={handleShowDraftModal}
        isParkSaleDisabled={
          transactionData?.paymentMethod ===
          transactionConstants.PAYMENT_COMPLETE
        }
        currentStore={currentStore}
      />
      <Row className="m-0">
        <Col md={6} className="payment-cart-wrapper container-scrollbar">
          <div className="payment-cart ">
            <div className="payment-cart-item-wrapper container-scrollbar">
              {transactionData?.inventory?.map((cartItem, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center w-100 mt-1"
                >
                  <div className="d-flex align-items-center gap-2">
                    <span className="opacity-75">{cartItem.quantity} x</span>
                    <span className="payment-cart-productname">
                      {cartItem.itemName}
                    </span>
                    <span className="opacity-50">
                      #{getShortestSku(cartItem.sku)}
                    </span>
                  </div>
                  <span className="payment-cart-productname">
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      cartItem.priceAfterDiscount
                    )}
                  </span>
                </div>
              ))}
            </div>

            <div className="">
              <div className="d-flex justify-content-between align-items-center pt-3 border-top w-100 mt-4">
                <div className="d-flex align-items-center gap-2">
                  <span className="subtotal-heading">Subtotal</span>
                </div>
                <span className="payment-cart-productname">
                  $
                  {parseToThousandSeperatorDecimalNumber(
                    transactionData?.subTotal
                  )}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center w-100 mt-1">
                <div className="d-flex align-items-center gap-2">
                  <span className="subtotal-heading">
                    Discount(
                    {`${transactionData?.discountedValue}${
                      transactionData?.discountMethod ? "%" : "$"
                    }`}
                    )
                  </span>
                </div>
                <span className="payment-cart-productname">
                  $
                  {parseToThousandSeperatorDecimalNumber(
                    transactionData?.discount
                  )}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center w-100 mt-1">
                <div className="d-flex align-items-center gap-2">
                  <span className="subtotal-heading">
                    Tax({transactionData?.store?.defaultTax || 0}%)
                  </span>
                </div>
                <span className="payment-cart-productname">
                  ${parseToThousandSeperatorDecimalNumber(transactionData?.tax)}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center w-100 mt-2 border-top pt-2">
                <div className="d-flex align-items-center gap-2">
                  <span className="subtotal-heading d-flex justify-centent-center align-items-center gap-3">
                    SALE TOTAL{" "}
                    <span className="opacity-75">
                      {getCountOfTotalItems(
                        transactionData?.inventory || 0,
                        "quantity"
                      ) || 0}{" "}
                      Items
                    </span>
                  </span>
                </div>
                <span className="payment-cart-productname">
                  $
                  {parseToThousandSeperatorDecimalNumber(
                    transactionData?.totalAmountToPay
                  )}
                </span>
              </div>

              {transactionData?.paymentHistory?.history?.length > 0 &&
                (() => {
                  let remainingTotal = parseToNumber(
                    transactionData.totalAmountToPay
                  );
                  return transactionData.paymentHistory.history.map(
                    (history) => {
                      if (history?.isUndoTransaction) {
                        remainingTotal -= history.currentAmountToPay;
                      }
                      return history?.isUndoTransaction ? (
                        <>
                          {history.paymentType !==
                            transactionConstants.GIFT_CARD && (
                            <div className="d-flex w-100 justify-content-between align-items-center mt-2 pt-2">
                              <div className="d-flex align-items-center gap-2">
                                <span className="subtotal-heading">
                                  {history.paymentType ===
                                  transactionConstants.CREDIT_CARD
                                    ? buttonNameConstants.CREDIT_CARD
                                    : history.paymentType ===
                                      transactionConstants.STORE_CREDIT
                                    ? buttonNameConstants.STORE_CREDIT
                                    : buttonNameConstants.CASH}
                                </span>
                              </div>
                              <span className="payment-cart-productname d-flex justify-content-center align-items-center gap-2">
                                $
                                {parseToThousandSeperatorDecimalNumber(
                                  history.currentAmountToPay
                                )}
                                {transactionData?.paymentMethod !==
                                  transactionConstants.PAYMENT_COMPLETE && (
                                  <span
                                    onClick={() => {
                                      handleReturnPayment(history);
                                    }}
                                    className="cursor-pointer"
                                  >
                                    <CloseIcon />
                                  </span>
                                )}
                              </span>
                            </div>
                          )}
                          {history.paymentType === transactionConstants.CASH &&
                            history.isUndoTransaction && (
                              <>
                                <div className="d-flex w-100 justify-content-between align-items-center mt-2 pt-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <span className="subtotal-heading gift-card-history-heading">
                                      Tendered Amount:
                                    </span>
                                  </div>
                                  <span className="payment-cart-productname gift-card-history-heading">
                                    $
                                    {parseToThousandSeperatorDecimalNumber(
                                      history.cashTenderedAmount
                                    )}
                                  </span>
                                </div>
                                <div className="d-flex w-100 justify-content-between align-items-center mt-2 pt-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <span className="subtotal-heading gift-card-history-heading">
                                      Change Owed:
                                    </span>
                                  </div>
                                  <span className="payment-cart-productname gift-card-history-heading">
                                    $
                                    {parseToThousandSeperatorDecimalNumber(
                                      history.cashChangeOwed
                                    )}
                                  </span>
                                </div>
                              </>
                            )}
                          {history.paymentType ===
                            transactionConstants.CREDIT_CARD &&
                            history.activeCardPaymentType ===
                              STORE_CARD_PAYMENT_TYPE_ENUMS.VERIFONE &&
                            history.isUndoTransaction && (
                              <>
                                <div className="d-flex w-100 justify-content-between align-items-center mt-2 pt-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <span className="subtotal-heading gift-card-history-heading">
                                      Card Type:
                                    </span>
                                  </div>
                                  <span className="payment-cart-productname gift-card-history-heading">
                                    {history.creditCard.paymentBrand}
                                  </span>
                                </div>
                                <div className="d-flex w-100 justify-content-between align-items-center mt-2 pt-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <span className="subtotal-heading gift-card-history-heading">
                                      Last 4:
                                    </span>
                                  </div>
                                  <span className="payment-cart-productname gift-card-history-heading">
                                    {history.creditCard.maskedPan}
                                  </span>
                                </div>{" "}
                                <div className="d-flex w-100 justify-content-between align-items-center mt-2 pt-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <span className="subtotal-heading gift-card-history-heading">
                                      Auth #:
                                    </span>
                                  </div>
                                  <span className="payment-cart-productname gift-card-history-heading">
                                    {history.creditCard.paymentTokenValue}
                                  </span>
                                </div>
                              </>
                            )}
                          {history.paymentType ===
                            transactionConstants.GIFT_CARD &&
                            history.isUndoTransaction && (
                              <>
                                <div className="d-flex w-100 justify-content-between align-items-center mt-2 pt-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <span className="subtotal-heading">
                                      {history?.giftCardName}
                                    </span>
                                  </div>
                                  <span className="payment-cart-productname d-flex justify-content-center align-items-center gap-2">
                                    $
                                    {parseToThousandSeperatorDecimalNumber(
                                      history.currentAmountToPay
                                    )}
                                    {transactionData?.paymentMethod !==
                                      transactionConstants.PAYMENT_COMPLETE && (
                                      <span
                                        onClick={() => {
                                          handleReturnPayment(history);
                                        }}
                                        className="cursor-pointer"
                                      >
                                        <CloseIcon />
                                      </span>
                                    )}
                                  </span>
                                </div>
                                <div className="d-flex w-100 justify-content-between align-items-center mt-2 pt-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <span className="subtotal-heading gift-card-history-heading">
                                      Remaining Balance
                                    </span>
                                  </div>
                                  <span className="payment-cart-productname gift-card-history-heading">
                                    $
                                    {parseToThousandSeperatorDecimalNumber(
                                      history.remainingBalance
                                    )}
                                  </span>
                                </div>
                              </>
                            )}

                          {remainingTotal <= 0 ? (
                            ""
                          ) : (
                            <div className="d-flex justify-content-between align-items-center w-100 mt-2 border-top pt-2">
                              <div className="d-flex align-items-center gap-2">
                                <span className="subtotal-heading d-flex justify-centent-center align-items-center gap-3">
                                  SALE TOTAL{" "}
                                  <span className="opacity-75">
                                    {getCountOfTotalItems(
                                      transactionData?.inventory || 0,
                                      "quantity"
                                    ) || 0}{" "}
                                    Items
                                  </span>
                                </span>
                              </div>
                              <span className="payment-cart-productname">
                                $
                                {parseToThousandSeperatorDecimalNumber(
                                  remainingTotal
                                )}
                              </span>
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      );
                    }
                  );
                })()}
            </div>
          </div>
        </Col>
        <Col md={6}>
          {transactionData?.paymentMethod ===
          transactionConstants.CASH_PAYMENT ? (
            <CashPayment
              changeOwed={changeOwed}
              amountTendered={amountTendered}
              transactionData={transactionData}
              setAmountTendered={setAmountTendered}
              handleCashPayment={handleCashPayment}
              setChangeOwed={setChangeOwed}
              setPaymentMethod={handlePaymentMethod}
              amountToPay={transactionData?.currentAmountToPay}
              handleAmountTenderedChange={handleAmountTenderedChange}
              handleFixedAmountButtonClick={handleFixedAmountButtonClick}
            />
          ) : transactionData?.paymentMethod ===
            transactionConstants.CREDIT_CARD_PAYMENT ? (
            <CardPayment
              spinnerArray={spinnerArray}
              currentStore={currentStore}
              storeDevices={storeDevices}
              transactionData={transactionData}
              setPaymentMethod={handlePaymentMethod}
              paymentDevice={transactionData.paymentDevice}
              amountToPay={transactionData?.currentAmountToPay}
              handleThirdPartyPayment={handleThirdPartyPayment}
              handleCreditCardPayment={handleCreditCardPayment}
              handleTransactionCancel={handleTransactionCancel}
            />
          ) : transactionData?.paymentMethod ===
            transactionConstants.GIFT_CARD_PAYMENT ? (
            <GiftCardPayment
              giftCardDetails={giftCardDetails}
              transactionData={transactionData}
              setPaymentMethod={handlePaymentMethod}
              amountToPay={transactionData?.currentAmountToPay}
              getGiftCardDetails={getGiftCardDetails}
              emptyGiftCardDetails={emptyGiftCardDetails}
              handleGiftCardPayment={handleGiftCardPayment}
            />
          ) : transactionData?.paymentMethod ===
            transactionConstants.PAYMENT_COMPLETE ? (
            <PaymentComplete
              transactionData={transactionData}
              handleClick={() => {}}
              handlePrintReceipt={handlePrintReceipt}
              setTransactionData={setTransactionData}
              sendEmailOrSMS={sendEmailOrSMS}
              handleShowDraftModal={handleShowDraftModal}
              currentStore={currentStore}
              handleAddCustomerClick={handleAddCustomerClick}
            />
          ) : (
            <>
              <div className="payment-div-wrapper mb-3">
                <Row className="m-0 w-100">
                  <Col md={12} className="px-0">
                    <span className="amount-to-pay-heading">
                      Select a Customer
                    </span>
                  </Col>
                  {!transactionData?.customer?.id ? (
                    <Col md={12} className="mt-2 px-0">
                      <CustomerDropdown
                        isGuestCustomers={true}
                        value={selectedCustomer}
                        dropdownIndicator={false}
                        handleAddCustomerClick={handleAddCustomerClick}
                        SelectIconImage={categoryDropdownConstants.SEARCH_ICON}
                        handleChange={(selectedOption) => {
                          setSelectedCustomer(selectedOption);
                          setTransactionData(
                            {
                              ...transactionData,
                              customer:
                                parseJsonObject(selectedOption?.value) || "",
                            },
                            currentStore?.id
                          );
                        }}
                        selectedCustomerFlag={selectedCustomerFlag}
                      />
                    </Col>
                  ) : (
                    <Col md={12} className="mt-2 px-0">
                      <SelectedCustomerCard
                        customer={transactionData?.customer}
                        isStoreCredit={true}
                        isDeleteEditOptions={
                          !parseToNumber(
                            transactionData?.paymentHistory?.totalPaidAmount[
                              transactionConstants.STORE_CREDIT
                            ]
                          ) > 0
                        }
                        handleCustomerDeleteClick={() => {
                          setSelectedCustomer("");
                          setTransactionData(
                            {
                              ...transactionData,
                              customer: "",
                            },
                            currentStore?.id
                          );
                        }}
                        handleAddCustomerClick={handleAddCustomerClick}
                        handleCustomerEditClick={handleCustomerEditClick}
                      />
                    </Col>
                  )}
                </Row>
              </div>
              <div className="payment-div-wrapper">
                <Row className="m-0 w-100">
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-center px-0"
                  >
                    <span className="amount-to-pay-heading">
                      Charge Payment
                    </span>
                  </Col>
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-center mt-4"
                  >
                    <span className="amount-to-pay-heading fw-normal">
                      Remaining Sale Total
                    </span>
                    <span className="amount-to-pay-heading ">
                      $
                      {parseToThousandSeperatorDecimalNumber(
                        parseToNumber(transactionData?.totalAmountToPay) -
                          parseToNumber(
                            getSumOfPaymentHistory(
                              transactionData?.paymentHistory
                            )
                          )
                      )}
                    </span>
                  </Col>
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-center mt-4"
                  >
                    <span className="amount-to-pay-heading fw-normal">
                      Enter Amount to Charge:
                    </span>
                    <div className="amount-to-paid-field-wrapper amount-to-charge-field-wrapper">
                      <span className="dollar-icon-alignment">$</span>
                      <input
                        type="number"
                        className="amount-to-paid-field"
                        value={transactionData?.currentAmountToPay}
                        step={0.01}
                        onChange={(e) => {
                          handleAmountToPayChange(e.target.value);
                        }}
                        onBlur={(e) =>
                          handleZeroOnBlur(e, 0, (value) => {
                            handleAmountToPayChange(e.target.value);
                          })
                        }
                        max={
                          transactionData?.totalAmountToPay -
                          getSumOfPaymentHistory(
                            transactionData?.paymentHistory ||
                              defaultPaymentHistory
                          )
                        }
                        min={1}
                      />
                    </div>
                  </Col>
                  <Col
                    md={12}
                    className="mt-4 d-flex justify-content-between gap-2 px-0 "
                  >
                    <Button
                      className="w-100"
                      IconImage={CashIcon}
                      label={buttonNameConstants.CASH}
                      handleClick={() => {
                        const checkAmount = checkAmountToPay();
                        if (!checkAmount) {
                          handlePaymentMethod(
                            transactionConstants.CASH_PAYMENT
                          );
                        }
                      }}
                    />
                    <Button
                      className="w-100"
                      IconImage={CreditcardIcon}
                      label={buttonNameConstants.CREDIT_CARD}
                      handleClick={() => {
                        const checkAmount = checkAmountToPay();
                        if (!checkAmount) {
                          handlePaymentMethod(
                            transactionConstants.CREDIT_CARD_PAYMENT
                          );
                        }
                      }}
                      isDisabled={transactionData?.subTotal === 0}
                    />
                    <Button
                      className="w-100"
                      IconImage={GiftCardIcon}
                      label={buttonNameConstants.GIFT_CARD_PAYMENT}
                      handleClick={() => {
                        const checkAmount = checkAmountToPay();
                        if (!checkAmount) {
                          handlePaymentMethod(
                            transactionConstants.GIFT_CARD_PAYMENT
                          );
                        }
                      }}
                      isDisabled={transactionData?.subTotal === 0}
                    />
                    <Button
                      IconImage={StoreCreditIcon}
                      label={buttonNameConstants.STORE_CREDIT}
                      handleClick={() => {
                        const checkAmount = checkAmountToPay();
                        if (!checkAmount) {
                          handleStoreCreditPayment();
                        }
                      }}
                      isDisabled={
                        Number(transactionData?.customer.currentBalance) ===
                          0 ||
                        transactionData?.subTotal === 0 ||
                        !transactionData?.customer?.id
                      }
                    />
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Col>
      </Row>
      {addCustomerModal && (
        <AddNewCustomerModal
          selectedCustomer={selectedCustomerCheck}
          addCustomerModal={addCustomerModal}
          customerToEdit={customerToEdit}
        />
      )}
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  printer: state.printer,
  customer: state.customer,
  transaction: state.transaction,
  user: state.authentication.user,
  spinnerArray: state.system.spinnerArray,
  currentStore: state.store.currentStore,
  storeDevices: state.device.storeDevices,
  giftCardDetails: state.transaction.giftCardDetails,
  isAddCustomerModal: state.transaction.isAddCustomerModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setTransactionData: (data, storeId) =>
    dispatch(transactionActions.setTransactionData(data, storeId)),
  newSaleTransaction: (
    data,
    transactionObject,
    handlePaymentMethod,
    handlePrintReceipt,
    isAutoPrint,
    isUndoTransaction,
    message,
    handleCashDrawer,
    DefaultPrinter
  ) =>
    dispatch(
      transactionActions.newSaleTransaction(
        data,
        transactionObject,
        handlePaymentMethod,
        handlePrintReceipt,
        isAutoPrint,
        isUndoTransaction,
        message,
        handleCashDrawer,
        DefaultPrinter
      )
    ),
  getStoreDevices: (storeId) =>
    dispatch(deviceActions.getStoreDevices(storeId)),
  getGiftCardDetails: (storeId, barcode) =>
    dispatch(transactionActions.getGiftCardDetails(storeId, barcode)),
  emptyGiftCardDetails: () =>
    dispatch(transactionActions.emptyGiftCardDetails()),
  toggleAddCustomerModal: (value) =>
    dispatch(transactionActions.toggleAddCustomerModal(value)),
  cancelCardTransaction: (data, transactionObject) =>
    dispatch(transactionActions.cancelCardTransaction(data, transactionObject)),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  parkSale: (data) => dispatch(transactionActions.parkSale(data)),
  handleNewParkSale: (data, currentStore) =>
    dispatch(transactionActions.handleNewParkSale(data, currentStore)),
  sendEmailOrSMS: (data) => dispatch(transactionActions.sendEmailOrSMS(data)),
  handleDraftModal: (isToggle) =>
    dispatch({
      type: transactionConstants.SET_DRAFT_CONFIRMATION,
      data: isToggle,
    }),
});

//-------Export transaction Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTransactionPayment);
