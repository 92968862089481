import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";

import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import {
  PRODUCT_CONDITIONS_ENUMS,
  PRODUCT_TYPES_ENUMS,
  SET_BY_MARGIN_ENUMS,
} from "../../../system/globalEnums";
import SearchField from "../../../shared/components/searchField/SearchField";
import CustomTooltip from "../../../shared/components/toolTipComponent/CustomTooltip";
import DataTableComponent from "../../../shared/components/table/ReactDataTableComponent";
import InputNumberField from "../../../shared/components/inputNumberField/InputNumberField";
import TableCustomAppliesToField from "../../../shared/components/table/TableCustomAppliesToField";
import TableCustomPriceRangeField from "../../../shared/components/table/TableCustomPriceRangeField";

import { tradeInDiscountActions } from "../../../redux/actions";

import {
  toastType,
  MARGIN_TYPES,
  toastMessages,
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  tradeInDiscountConstant,
  SET_BY_TRADE_IN_MARGIN_OPTIONS,
  inventoryConstants,
} from "../../../constants";
import {
  AddIcon,
  EditIcon,
  CrossIcon,
  DeleteIcon,
  DollarIcon,
  SearchIcon,
  BlueBoxIcon,
  PercentageIcon,
  ActionDotsIcons,
} from "../../../assets/icons/iconsProvider";
import {
  tradeInsGlobalValidationSchema,
  tradeInsMonthlyValidationSchema,
} from "../../../shared/validationSchema/validationSchema";
import {
  customToast,
  filterApplyToSearchOptions,
  handleMapSelectedOptionsInEdit,
  handleCategorySelect,
} from "../../../shared/utility";
import AddTradeInMargin from "./AddTradeInMargin/AddTradeInMargin";
const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

//-------TradeIns component start here
const TradeIns = (props) => {
  const {
    inventory,
    currentStore,
    tradeinMargin,
    addGlobalMargin,
    addMonthlyBudget,
    itemOrganization,
    addCategoryMargin,
    updateCategoryMargin,
    deleteCategoryMargin,
  } = props;

  //-----reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;

  const { productTags } = inventory;

  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [setByFilter, setSetByFilter] = useState("");
  const [editCategory, setEditCategory] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [itemMarginUnit, setItemMarginUnit] = useState(false);
  const [searchCategories, setSearchCategories] = useState([]);
  const [gridState, setGridState] = useState(defaultGridState);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [filterProductType, setFilterProductType] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [modalCheckBoxStatus, setModalCheckBoxStatus] = useState(false);
  const [filterProductCategory, setFilterProductCategory] = useState("");
  const [resetSearchCategories, setResetSearchCategories] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState(
    inventoryConstants.VIDEO_GAME_CONDITION[0]
  );
  const [defaultCategorySortFieldId, setDefaultCategorySortFieldId] =
    useState();
  let numOfRenders = useRef(0);
  const initialGlobalValues = {
    cashMargin:
      tradeinMargin?.globalMargin?.cashMarginPercentage ||
      globalConstants.EMPTY_STRING,
    tradeMargin:
      tradeinMargin?.globalMargin?.tradeinMarginPercentage ||
      globalConstants.EMPTY_STRING,
  };

  const [updatedRowsData, setupdatedRowsData] = useState([]);

  const initialValues = {
    monthlyPrice: currentStore?.budget || globalConstants.EMPTY_STRING,
  };
  const handleSwitchChange = (id, checkboxStatus) => {
    setModalCheckBoxStatus(checkboxStatus);
  };

  const productConditionList = [
    ...inventoryConstants.VIDEO_GAME_CONDITION,
    {
      value: PRODUCT_CONDITIONS_ENUMS.USED,
      label: PRODUCT_CONDITIONS_ENUMS.USED,
    },
  ];

  //-------Handle TradeIns edit click
  const handleEditClick = (row) => {
    const marginType = row.marginType;

    setIsEdit(true);
    setEditCategory({
      ...row,
      setBy:
        marginType === SET_BY_MARGIN_ENUMS.CATEGORY.value
          ? SET_BY_MARGIN_ENUMS.CATEGORY.value
          : marginType === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
          ? SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
          : marginType === SET_BY_MARGIN_ENUMS.PRODUCT.value
          ? SET_BY_MARGIN_ENUMS.PRODUCT.value
          : SET_BY_MARGIN_ENUMS.TYPE.value,
    });
    handleSetByChange(
      marginType === SET_BY_MARGIN_ENUMS.CATEGORY.value
        ? SET_BY_MARGIN_ENUMS.CATEGORY.value
        : marginType === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
        ? SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
        : marginType === SET_BY_MARGIN_ENUMS.PRODUCT.value
        ? SET_BY_MARGIN_ENUMS.PRODUCT.value
        : SET_BY_MARGIN_ENUMS.TYPE.value
    );
    toggleCustomTradeModal();
    setSelectedCategories(
      handleMapSelectedOptionsInEdit(row.marginGroup, marginType)
    );

    setSelectedCondition(
      marginType === SET_BY_MARGIN_ENUMS.PRODUCT.value
        ? productConditionList.find(
            (condition) => condition.value === row.productCondition
          )
        : productConditionList[0]
    );

    setSelectedTags(
      marginType === SET_BY_MARGIN_ENUMS.PRODUCT.value ? row.tags : []
    );

    setItemMarginUnit(
      row.productMarginSetBy === tradeInDiscountConstant.FIXED_VALUE
        ? true
        : false
    );

    if (row.minPriceRange >= 0 && row.maxPriceRange >= 0) {
      setModalCheckBoxStatus(true);
    }
    const filterCategory = filterApplyToSearchOptions(
      marginType,
      customTypes.filter(
        (item) => item.productType !== PRODUCT_TYPES_ENUMS.ALL
      ),
      customCategories,
      customSubCategories,
      filterProductType,
      filterProductCategory,
      selectedCategories
    );

    const categoriesNames = row.marginGroup.map((row) => {
      return marginType === SET_BY_MARGIN_ENUMS.CATEGORY.value
        ? row.categoryName
        : marginType === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
        ? row.subcategoryName
        : row.productType;
    });

    setSearchCategories(
      filterCategory.filter((category) => {
        return !categoriesNames.includes(category.name);
      })
    );
  };

  //-------Handle TradeIns delete click
  const handleDeleteClick = (row) => {
    deleteCategoryMargin(currentStore?.id, [String(row.id)]);
  };

  //-------Handle add button click
  const handleAddClick = () => {
    setResetSearchCategories(true);
    toggleCustomTradeModal();
    handleSetByChange(SET_BY_MARGIN_ENUMS.TYPE.value);
  };
  const toggleCustomTradeModal = (resetSearch) => {
    setShow(!show);
    setModalCheckBoxStatus(false);
    setSelectedCategories([]);
    if (resetSearch || isEdit || resetSearchCategories) {
      setResetSearchCategories(false);
      setIsEdit(false);
      setEditCategory("");
    }
  };

  const removeSelectedCategory = (
    categoryToRemove,
    isProductMargin = false
  ) => {
    if (isProductMargin) {
      setSelectedCategories(
        selectedCategories.filter(
          (product) => product.product_id !== categoryToRemove
        )
      );
    } else {
      setSelectedCategories(
        selectedCategories.filter(
          (category) => category.name !== categoryToRemove
        )
      );

      const fullCategoryObject = selectedCategories.find(
        (category) => category.name === categoryToRemove
      );

      if (fullCategoryObject) {
        setSearchCategories(
          [...searchCategories, fullCategoryObject].sort((a, b) =>
            a.name.localeCompare(b.name)
          )
        );
      }
    }
  };

  const globalHandleSubmit = (data, { resetForm }) => {
    const dataToSend = {
      marginType: MARGIN_TYPES.GLOBAL,
      cashMarginPercentage: data.cashMargin,
      tradeinMarginPercentage: data.tradeMargin,
      store: {
        name: currentStore.storeName,
        id: currentStore.id,
      },
    };
    addGlobalMargin(currentStore?.id, dataToSend);
    resetForm();
  };

  const modalHandleSubmit = (data) => {
    if (selectedCategories?.length <= 0) {
      customToast(
        data.setBy === SET_BY_MARGIN_ENUMS.PRODUCT.value
          ? toastMessages.PLEASE_SELECT_PRODUCTS
          : toastMessages.PLEASE_SELECT_CATEGORIES,
        toastType.ERROR
      );
      return;
    }

    let dataToSend = [];
    if (data.setBy === SET_BY_MARGIN_ENUMS.TYPE.value) {
      const updatedSelectedCategories = selectedCategories.map((items) => {
        const newObject = {
          productType: items.name,
          cashMarginPercentage: data.cashOffer,
          tradeinMarginPercentage: data.tradeOffer,
          minPriceRange: modalCheckBoxStatus
            ? data.minPrice
            : tradeInDiscountConstant.DEFAULT_MIN_RANGE,
          maxPriceRange: modalCheckBoxStatus
            ? data.maxPrice
            : tradeInDiscountConstant.DEFAULT_MAX_RANGE,
        };
        return newObject;
      });
      dataToSend = {
        id: data.id,
        marginType: SET_BY_MARGIN_ENUMS.TYPE.value,
        cashMarginPercentage: data.cashOffer,
        tradeinMarginPercentage: data.tradeOffer,
        minPriceRange: modalCheckBoxStatus
          ? data.minPrice
          : tradeInDiscountConstant.DEFAULT_MIN_RANGE,
        maxPriceRange: modalCheckBoxStatus
          ? data.maxPrice
          : tradeInDiscountConstant.DEFAULT_MAX_RANGE,
        marginGroup: updatedSelectedCategories,
        store: {
          id: currentStore.id,
          name: currentStore.storeName,
        },
      };
    } else if (data.setBy === SET_BY_MARGIN_ENUMS.CATEGORY.value) {
      const updatedSelectedCategories = selectedCategories.map((items) => {
        const newObject = {
          categoryId: items.id,
          categoryName: items.name,
        };
        return newObject;
      });
      dataToSend = {
        id: data.id,
        marginType: SET_BY_MARGIN_ENUMS.CATEGORY.value,
        cashMarginPercentage: data.cashOffer,
        tradeinMarginPercentage: data.tradeOffer,
        minPriceRange: modalCheckBoxStatus
          ? data.minPrice
          : tradeInDiscountConstant.DEFAULT_MIN_RANGE,
        maxPriceRange: modalCheckBoxStatus
          ? data.maxPrice
          : tradeInDiscountConstant.DEFAULT_MAX_RANGE,
        marginGroup: updatedSelectedCategories,
        store: {
          id: currentStore.id,
          name: currentStore.storeName,
        },
      };
    } else if (data.setBy === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value) {
      const updatedSelectedCategories = selectedCategories.map((items) => {
        const newObject = {
          categoryId: items.id,
          categoryName: items.name,
          parentCategoryId: items.parentCategoryId,
          parentCategoryName: items.parentCategoryName,
        };
        return newObject;
      });
      dataToSend = {
        id: data.id,
        marginType: SET_BY_MARGIN_ENUMS.SUBCATEGORY.value,
        cashMarginPercentage: data.cashOffer,
        tradeinMarginPercentage: data.tradeOffer,
        minPriceRange: modalCheckBoxStatus
          ? data.minPrice
          : tradeInDiscountConstant.DEFAULT_MIN_RANGE,
        maxPriceRange: modalCheckBoxStatus
          ? data.maxPrice
          : tradeInDiscountConstant.DEFAULT_MAX_RANGE,
        marginGroup: updatedSelectedCategories,
        store: {
          id: currentStore.id,
          name: currentStore.storeName,
        },
      };
    } else if (data.setBy === SET_BY_MARGIN_ENUMS.PRODUCT.value) {
      const updatedSelectedProducts = selectedCategories.map((item) => ({
        product_id: item.product_id,
        product_name: item.product_name,
        productType: item.productType,
        category_name: item.category_name,
        cardRarity: item.cardRarity,
        subcategory: item.subcategory,
      }));

      dataToSend = {
        id: data.id,
        tags: selectedTags,
        productCondition: selectedCondition?.value,
        marginType: SET_BY_MARGIN_ENUMS.PRODUCT.value,
        cashMarginPercentage: data.cashOffer,
        productMarginSetBy: itemMarginUnit
          ? tradeInDiscountConstant.FIXED_VALUE
          : tradeInDiscountConstant.PERCENTAGE,
        tradeinMarginPercentage: data.tradeOffer,
        minPriceRange: modalCheckBoxStatus
          ? data.minPrice
          : tradeInDiscountConstant.DEFAULT_MIN_RANGE,
        maxPriceRange: modalCheckBoxStatus
          ? data.maxPrice
          : tradeInDiscountConstant.DEFAULT_MAX_RANGE,
        marginGroup: updatedSelectedProducts,
        store: {
          id: currentStore.id,
          name: currentStore.storeName,
        },
      };
    }

    if (isEdit) {
      updateCategoryMargin(
        currentStore?.id,
        dataToSend,
        toggleCustomTradeModal
      );
    } else {
      addCategoryMargin(
        currentStore?.id,
        dataToSend,
        toggleCustomTradeModal,
        resetSearch
      );
    }
  };
  const HandleSubmit = (data) => {
    addMonthlyBudget(currentStore?.id, {
      budget: data.monthlyPrice,
    });
  };

  const columnHeaders = [
    {
      id: "setBy",
      name: "Set By",
      selector: (row) => row.marginType,
      cell: (row) => {
        return (
          <span className="users-cell-max-size">
            {
              SET_BY_TRADE_IN_MARGIN_OPTIONS.find(
                (item) => item.value === row.marginType
              ).label
            }
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "appliesTo",
      name: "Applies To",
      selector: (row) => row.marginGroup,
      cell: (row) => {
        return (
          <TableCustomAppliesToField
            row={row}
            groupArray={row.marginGroup}
            groupType={row.marginType}
          />
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "priceRange",
      name: "Price Range",
      selector: (row) => row.maxPriceRange,
      cell: (row) => {
        return <TableCustomPriceRangeField row={row} />;
      },
      sortable: true,
      sortDirection: null,
    },

    {
      id: "cashPercentage",
      name: "Cash Offer",
      selector: (row) => row.cashMarginPercentage,
      cell: (row) => (
        <span className="users-cell-max-size">
          {row.productMarginSetBy === tradeInDiscountConstant.FIXED_VALUE
            ? `$${row.cashMarginPercentage}`
            : `${row.cashMarginPercentage}%`}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "tradePercentage",
      name: "Trade Offer",
      selector: (row) => row.tradeinMarginPercentage,
      cell: (row) => (
        <span className="users-cell-max-size">
          {row.productMarginSetBy === tradeInDiscountConstant.FIXED_VALUE
            ? `$${row.tradeinMarginPercentage}`
            : `${row.tradeinMarginPercentage}%`}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            <CustomTooltip anchorSelect={`#tool${row.id}`}>
              <Button
                label={buttonNameConstants.EDIT}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={EditIcon}
                handleClick={() => handleEditClick(row)}
              />
              <Button
                label={buttonNameConstants.DELETE}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={DeleteIcon}
                handleClick={() => handleDeleteClick(row)}
              />
            </CustomTooltip>
            <button id={`tool${row.id}`} className="action-Button-Wrapper">
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];

  const [columns, setColumns] = useState([]);

  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };

  //-------Handle searchfield change
  const handleFilterData = (e) => {
    const filterList = updatedRowsData.filter((row) => {
      return (
        String(
          row.categoryName +
            row.maxPriceRange +
            row.minPriceRange +
            row.cashMarginPercentage +
            row.tradeinMarginPercentage
        )
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    // reset the pagination
    numOfRenders.current++;
    return filterList;
  };

  const categoriesColumn = [
    {
      id: "categoryName",
      name: "Category Name",
      selector: (row) => row.name,
      cell: (row) => <span className="users-cell-max-size">{row.name}</span>,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            {
              <button
                type="button"
                onClick={() => {
                  removeSelectedCategory(row?.name);
                }}
                id={`tool${row.id}`}
                className="action-Button-Wrapper"
              >
                <DeleteIcon className="cursor-pointer" />
              </button>
            }
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];

  const productMarginColumns = [
    {
      id: "name",
      name: "Name",
      selector: (row) => row.product_name,
      cell: (row) => (
        <span className="users-cell-max-size">{row.product_name}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "type",
      name: "Type",
      selector: (row) => row.productType,
      cell: (row) => (
        <span className="users-cell-max-size">{row.productType}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "category_name",
      name: "Category",
      selector: (row) => row.category_name,
      cell: (row) => (
        <span className="users-cell-max-size">{row.category_name}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            {
              <button
                type="button"
                onClick={() => {
                  removeSelectedCategory(row?.product_id, true);
                }}
                id={`tool${row.id}`}
                className="action-Button-Wrapper"
              >
                <DeleteIcon className="cursor-pointer" />
              </button>
            }
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];

  const handleSetByChange = (option) => {
    setSelectedCategories([]);
    setSetByFilter(option);
    setSearchCategories(
      filterApplyToSearchOptions(
        option,
        customTypes.filter(
          (item) => item.productType !== PRODUCT_TYPES_ENUMS.ALL
        ),
        customCategories,
        customSubCategories,
        filterProductType,
        filterProductCategory,
        selectedCategories
      )
    );
    setItemMarginUnit(false);
    setSelectedCondition(inventoryConstants.VIDEO_GAME_CONDITION[0]);
    setSelectedTags([]);
    setModalCheckBoxStatus(false);
  };

  const handleSelectedTagsChange = (value) => {
    setSelectedTags(value?.map((tag) => tag.label) || []);
  };

  useEffect(() => {
    setSearchCategories(
      filterApplyToSearchOptions(
        setByFilter,
        customTypes.filter(
          (item) => item.productType !== PRODUCT_TYPES_ENUMS.ALL
        ),
        customCategories,
        customSubCategories,
        filterProductType,
        filterProductCategory,
        selectedCategories
      )
    ); // eslint-disable-next-line
  }, [setByFilter, filterProductType, filterProductCategory]);

  useEffect(() => {
    setColumns(columnHeaders);
    // eslint-disable-next-line
  }, [currentStore]);

  useEffect(() => {
    handleSetByChange(SET_BY_MARGIN_ENUMS.TYPE.value);
    // eslint-disable-next-line
  }, [customCategories]);

  useEffect(() => {
    setupdatedRowsData([
      ...(tradeinMargin?.categoryMargins || []),
      ...(tradeinMargin?.subcategoryMargins || []),
      ...(tradeinMargin?.productTypeMargins || []),
      ...(tradeinMargin?.productMargins || []),
    ]);
  }, [tradeinMargin]);

  const clearSearchText = () => {
    setSearchText("");
  };
  const resetSearch = () => {
    setResetSearchCategories(true);
  };

  //-------Return TradeIns Component
  return (
    <>
      <Topbar title="Trade-Ins"></Topbar>
      <Row className="m-0 w-100">
        <Col md={12} className="tradeIns-heading">
          Custom Trade-In Margin
        </Col>
        <Col md={8} className="p-0">
          <Row className="m-0 h-100  user-screen-wrapper w-100 min-vh-100 ">
            <Row className="m-0  p-0 w-100 user-screen-wrapper">
              <Col md={9} className="">
                <SearchField
                  onChange={(e) => setSearchText(e.target.value)}
                  IconImage={SearchIcon}
                  label="Search"
                  placeHolder="Type or Scan"
                  value={searchText}
                  CrossIcon={searchText ? CrossIcon : ""}
                  clearSearchText={clearSearchText}
                  BlueBoxIcon={BlueBoxIcon}
                />
              </Col>
              <Col
                md={3}
                className="d-flex justify-content-end align-items-end gap-3"
              >
                <Button
                  label={buttonNameConstants.ADD}
                  IconImage={AddIcon}
                  handleClick={handleAddClick}
                  className="w-100"
                />
              </Col>
            </Row>
            <Row className="m-0 p-0  w-100 h-100 user-screen-wrapper  pt-3">
              <DataTableComponent
                className="tradeIn-table-height"
                key={numOfRenders.current}
                columns={columns}
                data={searchText ? handleFilterData() : updatedRowsData}
                onSort={onTableSort}
                defaultSortFieldId={defaultSortFieldId}
                gridState={gridState}
                setGridState={updateGridState}
                total={
                  searchText
                    ? handleFilterData()?.length
                    : updatedRowsData?.length
                }
              />
            </Row>
          </Row>
        </Col>
        <Col md={4}>
          <Row className="m-0 user-screen-wrapper container-scrollbar">
            <Formik
              initialValues={initialGlobalValues}
              validate={tradeInsGlobalValidationSchema}
              onSubmit={globalHandleSubmit}
              enableReinitialize
            >
              {({ resetForm }) => (
                <Form className="px-0">
                  <Row className="global-trade-div">
                    <p className="tradeIns-heading p-0">
                      Global Trade-in Margin
                    </p>
                    <Col md={12} className="mb-3  p-0">
                      <InputNumberField
                        className="input-field-background-tradeIns"
                        name="cashMargin"
                        placeHolder="35 %"
                        type="number"
                        label="Cash Margin"
                        maxValue={100}
                        IconImage={PercentageIcon}
                      />
                    </Col>
                    <Col md={12} className="mb-3  p-0">
                      <InputNumberField
                        className="input-field-background-tradeIns"
                        name="tradeMargin"
                        placeHolder="35 %"
                        type="number"
                        label="Trade Margin"
                        maxValue={100}
                        IconImage={PercentageIcon}
                      />
                    </Col>
                    <Col
                      md={12}
                      className="d-flex mt-2 p-0 justify-content-end align-items-center gap-3"
                    >
                      <Button
                        type="button"
                        label={buttonNameConstants.CANCEL}
                        handleClick={() => {
                          // handleCancelButton();
                          resetForm();
                        }}
                        buttonType={buttonTypeConstants.GHOST_BUTTON}
                        className="min-width-btn-trads"
                      ></Button>
                      <Button
                        type="submit"
                        label={buttonNameConstants.SAVE}
                        handleClick={() => {
                          // handleCancelButton();
                        }}
                        buttonType={buttonTypeConstants.LIGHT_PURPLE}
                        className="min-width-btn-trads"
                      ></Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Row>
          <Row className="m-0 mt-3">
            <Formik
              initialValues={initialValues}
              validate={tradeInsMonthlyValidationSchema}
              onSubmit={HandleSubmit}
              enableReinitialize
            >
              {({ resetForm }) => (
                <Form className="px-0">
                  <Row className="global-trade-div">
                    <p className="tradeIns-heading mb-1 p-0">Monthly Budget</p>
                    <p className="monthly-budget-heading p-0">
                      Resets on the first day of the month
                    </p>
                    <Col md={12} className="mb-3  p-0">
                      <InputNumberField
                        className="input-field-background-tradeIns"
                        name="monthlyPrice"
                        placeHolder="$5,000.00"
                        type="number"
                        IconImage={DollarIcon}
                      />
                    </Col>

                    <Col
                      md={12}
                      className="d-flex mt-2 p-0 justify-content-end align-items-center gap-3"
                    >
                      <Button
                        type="button"
                        label={buttonNameConstants.CANCEL}
                        handleClick={() => {
                          // handleCancelButton();
                          resetForm();
                        }}
                        buttonType={buttonTypeConstants.GHOST_BUTTON}
                        className="min-width-btn-trads"
                      ></Button>
                      <Button
                        type="submit"
                        label={buttonNameConstants.SAVE}
                        handleClick={() => {
                          // handleCancelButton();
                        }}
                        buttonType={buttonTypeConstants.LIGHT_PURPLE}
                        className="min-width-btn-trads"
                      ></Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Row>
        </Col>
      </Row>

      <AddTradeInMargin
        show={show}
        isEdit={isEdit}
        columns={columns}
        setColumns={setColumns}
        productTags={productTags}
        customTypes={customTypes}
        setByFilter={setByFilter}
        selectedTags={selectedTags}
        editCategory={editCategory}
        itemMarginUnit={itemMarginUnit}
        searchCategories={searchCategories}
        categoriesColumn={categoriesColumn}
        customCategories={customCategories}
        setItemMarginUnit={setItemMarginUnit}
        selectedCondition={selectedCondition}
        modalHandleSubmit={modalHandleSubmit}
        filterProductType={filterProductType}
        handleSetByChange={handleSetByChange}
        selectedCategories={selectedCategories}
        handleSwitchChange={handleSwitchChange}
        setSearchCategories={setSearchCategories}
        customSubCategories={customSubCategories}
        modalCheckBoxStatus={modalCheckBoxStatus}
        productConditionList={productConditionList}
        productMarginColumns={productMarginColumns}
        setSelectedCondition={setSelectedCondition}
        setFilterProductType={setFilterProductType}
        handleCategorySelect={handleCategorySelect}
        setSelectedCategories={setSelectedCategories}
        filterProductCategory={filterProductCategory}
        toggleCustomTradeModal={toggleCustomTradeModal}
        handleSelectedTagsChange={handleSelectedTagsChange}
        setFilterProductCategory={setFilterProductCategory}
        defaultCategorySortFieldId={defaultCategorySortFieldId}
        setDefaultCategorySortFieldId={setDefaultCategorySortFieldId}
      />
    </>
  );
}; //-------TradeIns component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  currentStore: state.store.currentStore,
  itemOrganization: state.itemOrganization,
  tradeinMargin: state.tradeDiscount.tradeinMargin,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addGlobalMargin: (storeId, data) => {
    dispatch(tradeInDiscountActions.addGlobalMargin(storeId, data));
  },
  addCategoryMargin: (storeId, data, toggleCustomTradeModal, resetSearch) => {
    dispatch(
      tradeInDiscountActions.addCategoryMargin(
        storeId,
        data,
        toggleCustomTradeModal,
        resetSearch
      )
    );
  },
  updateCategoryMargin: (storeId, data, toggleCustomTradeModal) => {
    dispatch(
      tradeInDiscountActions.updateCategoryMargin(
        storeId,
        data,
        toggleCustomTradeModal
      )
    );
  },
  deleteCategoryMargin: (storeId, id) => {
    dispatch(tradeInDiscountActions.deleteCategoryMargin(storeId, id));
  },
  addMonthlyBudget: (storeId, data) => {
    dispatch(tradeInDiscountActions.addMonthlyBudget(storeId, data));
  },
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(TradeIns);
