import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";

import { transactionService } from "../../../../services";
import TradePaymentComplete from "./TradePaymentComplete";
import Topbar from "../../../../shared/components/topbar/Topbar";
import Button from "../../../../shared/components/Buttons/Button";
import CustomerImage from "../../../../assets/images/customerImage.svg";
import { systemActions, transactionActions } from "../../../../redux/actions";
import AddNewCustomerModal from "../../NewTransaction/AddNewCustomer/AddNewCustomerModal";
import { printReceiptData } from "../../../../shared/Printers/ReceiptPrinters/printReceipt";
import SelectedCustomerCard from "../../../../shared/components/shared/SelectedCustomerCard";
import CustomerDropdown from "../../../../shared/components/selectDropdown/CustomerDropdown";
import TradePrintLabelModal from "../../../Inventory/Items/printingLabelModal/TradePrintLabelModal";
import { handleOpenCashDrawer } from "../../../../shared/Printers/ReceiptPrinters/prepareReceiptForPrinter";
import { prepareReceiptForPrinterTrade } from "../../../../shared/Printers/ReceiptPrinters/prepareReceiptForPrinterTrade";
import {
  toastType,
  toastMessages,
  globalConstants,
  routesPathConstants,
  buttonNameConstants,
  buttonTypeConstants,
  transactionConstants,
  categoryDropdownConstants,
  tradeTransactionDefaultData,
} from "../../../../constants";
import {
  customToast,
  getShortestSku,
  parseJsonObject,
  stringifyObject,
  isSpinnerEnabled,
  handleEmptyOnFocus,
  updateInventorySKU,
  getReceiptPrintDate,
  getUnitPurchasePrice,
  parseToDecimalNumber,
  getSumOfPaymentHistory,
  convertToFixedPrecision,
  getProductLabelNotifications,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";

const NewTradePayment = (props) => {
  const {
    user,
    printer,
    customer,
    transaction,
    spinnerArray,
    currentStore,
    sendEmailOrSMS,
    activateSpinner,
    setNewTradeData,
    deactivateSpinner,
    userNotifications,
    isAddCustomerModal,
    newTradeTransaction,
    toggleAddCustomerModal,
  } = props;

  const navigate = useNavigate();
  const { state } = useLocation();
  //------ reducers
  const { allCustomers } = customer;
  const { newTradeData } = transaction;
  const { defaultReceiptPrinter } = printer;
  const { userPaymentType, inventory } = newTradeData;

  const cartItems = inventory;

  //------ useState
  const [isConsent, setIsConsent] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [customerToEdit, setCustomerToEdit] = useState({});
  const [printLabelModal, setPrintLabelModal] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  let cartItemsCount = useRef(newTradeData?.inventory?.length ?? 0);
  const [selectedCustomerFlag, setSelectedCustomerFlag] = useState(false);

  //-------Toggle Print Label Modal
  const togglePrintLabelModal = () => {
    setPrintLabelModal(!printLabelModal);
  };

  const handleViewCart = (row) => {
    setSelectedRows([]);

    const labelPrintProducts = getProductLabelNotifications(userNotifications);

    const reversedArray = labelPrintProducts;
    setSelectedRows(
      reversedArray[0]?.products.map((item) => {
        const newObject = {
          sku: item.sku,
          product_name: item.product_name,
          price: {
            quantity: item?.price?.quantity,
            type: item?.price?.type,
            unit_sell_price: item?.price?.unit_sell_price,
            dbQuantity: item.price.dbQuantity,
            isPriceChanged: item.price.isPriceChanged,
            isProductExisted: item.price.isProductExisted,
            quantityAdded: item.price.quantityAdded,
          },
          category_name: item?.category_name,
          productType: item?.productType,
          tags: item?.tags,
        };
        return newObject;
      })
    );
    togglePrintLabelModal();
  };

  const calcullateItems = () => {
    if (Array.isArray(cartItems) && cartItems.length > 0) {
      return cartItems.reduce((acc, item) => {
        return acc + item?.price?.quantity;
      }, 0);
    }
    return 0;
  };

  const handleTradeData = (data) => {
    setNewTradeData(data, currentStore?.id);
  };

  //-------handle amount to pay change
  const handleAmountToPayChange = (value) => {
    handleTradeData({ ...newTradeData, amountToPay: value });
  };

  //------ handle customer edit
  const handleCustomerEditClick = () => {
    const customer = allCustomers?.find(
      (customer) => customer.id === newTradeData?.customer?.id
    );
    if (customer?.id) {
      setCustomerToEdit(customer);
      toggleAddCustomerModal(true);
    }
  };

  const getTradeId = async () => {
    return transactionService.getTradeId().then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        customToast(error, toastType.ERROR);
      }
    );
  };
  const addTradeTransaction = async (paymentType, poiId) => {
    if (newTradeData?.amountToPay > newTradeData?.totalAmountToPay) {
      customToast(toastMessages.AMOUNT_TO_PAY_LESS, toastType.ERROR);
      return;
    } else if (newTradeData?.amountToPay <= 0) {
      customToast(toastMessages.AMOUNT_TO_PAY_GREATER, toastType.ERROR);
      return;
    }

    activateSpinner(transactionConstants.GET_TRADE_ID);
    const tradeId = newTradeData?.id || (await getTradeId());
    if (
      newTradeData?.userPaymentType === globalConstants.CREDIT &&
      (!newTradeData?.customer?.id || newTradeData?.customer === "")
    ) {
      customToast(toastMessages.PLEASE_SELECT_A_CUSTOMER, toastType.ERROR);
      deactivateSpinner(transactionConstants.GET_TRADE_ID);
      return;
    }

    if (tradeId) {
      //------- get transaction type
      const transationType =
        newTradeData?.paymentHistory?.totalPaidAmount[
          transactionConstants.CASH
        ] > 0 ||
        newTradeData?.paymentHistory?.totalPaidAmount[
          transactionConstants.STORE_CREDIT
        ] > 0
          ? transactionConstants.PROCESSED
          : Number(newTradeData?.amountToPay) ===
            Number(newTradeData?.totalAmountToPay)
          ? transactionConstants.PROCESSED
          : transactionConstants.DRAFT;

      //------ check payemnt is split or not
      const isSplitPayment =
        newTradeData?.paymentHistory?.totalPaidAmount[
          transactionConstants.CASH
        ] > 0 ||
        newTradeData?.paymentHistory?.totalPaidAmount[
          transactionConstants.STORE_CREDIT
        ] > 0
          ? transactionConstants.SPLIT_PAYMENT
          : Number(newTradeData?.amountToPay) ===
            Number(newTradeData?.totalAmountToPay)
          ? transactionConstants.PROCESSED
          : transactionConstants.DRAFT;

      //------ prepare data to send for trade transaction
      const dataToSend = {
        ...newTradeData,
        id: tradeId,
        store: {
          id: newTradeData?.store?.id,
          name: newTradeData?.store?.storeName || newTradeData?.store?.name,
        },
        customer: newTradeData?.customer,
        inventory: newTradeData?.inventory,
        creditUsed: 0,
        TransactionType: transationType,
        SplitCheck: isSplitPayment,
        PaymentType: newTradeData?.userPaymentType,
        Tax: newTradeData?.tax,
        SubTotal: newTradeData.SubTotal,
        currentAmountToPay: Number(
          Number(newTradeData?.amountToPay).toFixed(2)
        ),
        totalAmountToPay: newTradeData?.totalAmountToPay,
        paymentHistory: newTradeData?.paymentHistory,
        isConsent: newTradeData?.isConsent || isConsent,
      };

      newTradeTransaction(
        dataToSend,
        newTradeData,
        currentStore?.id,
        currentStore?.companyId,
        handleOpenCashDrawer,
        defaultReceiptPrinter
      );
      deactivateSpinner(transactionConstants.GET_TRADE_ID);
    }
  };
  const handlePayment = async () => {
    if (userPaymentType === globalConstants.CASH) {
      addTradeTransaction(transactionConstants.CASH);
    } else {
      addTradeTransaction(transactionConstants.STORE_CREDIT);
    }
  };
  function getBackNavigation() {
    handleShowDraftModal();
    const historyCheck =
      (newTradeData?.paymentHistory?.totalPaidAmount[
        transactionConstants.CASH
      ] || 0) +
        (newTradeData?.paymentHistory?.totalPaidAmount[
          transactionConstants.STORE_CREDIT
        ] || 0) >
      0
        ? true
        : false;
    if (historyCheck) {
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRADE);
      handleTradeData(tradeTransactionDefaultData);
    } else {
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRADE);
    }
  }
  const handleAddCustomerClick = (customerToEdit = {}) => {
    setCustomerToEdit(customerToEdit);
    toggleAddCustomerModal(true);
  };

  const handleShowDraftModal = () => {
    // handle component unmounting
    cartItemsCount.current = 0;
  };

  const handlePrintReceipt = (newTradeData, giftReceipt) => {
    const partialPayment =
      (newTradeData?.paymentHistory?.totalPaidAmount[
        transactionConstants.CASH
      ] || 0) +
        (newTradeData?.paymentHistory?.totalPaidAmount[
          transactionConstants.STORE_CREDIT
        ] || 0) >
        0 &&
      newTradeData?.totalAmountToPay !== newTradeData?.currentAmountToPay;
    const printData = prepareReceiptForPrinterTrade(
      user,
      newTradeData?.id,
      newTradeData?.paymentHistory,
      currentStore,
      newTradeData?.customer,
      [
        ...newTradeData?.inventory.map((inv) => {
          return {
            ...inv,
            cogs: inv?.price?.unit_purchase_price,
            item: inv?.product_name,
            priceAfterDiscount: partialPayment
              ? getUnitPurchasePrice(newTradeData, inv)
              : inv?.price?.unit_purchase_price,
            itemName: inv?.product_name,
            quantity: inv?.price?.quantity,
            productType: inv?.productType,
            category: inv?.category_name,
          };
        }),
      ],
      newTradeData?.discount,
      newTradeData?.SubTotal,
      newTradeData?.tax,
      newTradeData?.totalAmountToPay,
      0,
      0,
      true,
      partialPayment,
      giftReceipt,
      getReceiptPrintDate(newTradeData.createdOn)
    );
    printReceiptData(printData, defaultReceiptPrinter);
  };
  const selectedCustomer = (selectedCustomerFlag) => {
    setSelectedCustomerFlag(selectedCustomerFlag);
  };

  useEffect(() => {
    if (allCustomers && allCustomers.length > 0 && selectedCustomerFlag) {
      handleTradeData({
        ...newTradeData,
        customer: {
          ...allCustomers[0],
          name: allCustomers[0].firstName + " " + allCustomers[0].lastName,
        },
      });
      setSelectedCustomerFlag(false);
    } // eslint-disable-next-line
  }, [allCustomers]);

  useEffect(() => {
    if (!newTradeData) {
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRADE);
    } // eslint-disable-next-line
  }, [newTradeData]);
  useEffect(() => {
    setAddCustomerModal(isAddCustomerModal);
  }, [isAddCustomerModal]);

  // // handle component unmounting
  // useEffect(() => {
  //   return () => {
  //     if (cartItemsCount.current > 0) {
  //       handleDraftModal(transactionConstants.TRADE_DRAFT_MODAL);
  //     }
  //   }; // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (newTradeData?.paymentMethod === transactionConstants.PAYMENT_COMPLETE) {
      handleShowDraftModal();
    } // eslint-disable-next-line
  }, [newTradeData.paymentMethod]);

  useEffect(() => {
    if (state.currentStore.id !== currentStore.id) {
      handleShowDraftModal();
      handleTradeData(tradeTransactionDefaultData);
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRADE);
    } // eslint-disable-next-line
  }, [currentStore]);

  return (
    <>
      <Topbar
        title="Payment"
        isNewSalePayment={true}
        totalCartItems={cartItems?.length || 0}
        exportButton={
          newTradeData?.paymentMethod ===
            transactionConstants.PAYMENT_COMPLETE &&
          !(
            newTradeData?.inventory?.length === 1 &&
            newTradeData?.inventory[0]?.product_id ===
              transactionConstants.QUICK_TRADE
          )
        }
        buttonType={buttonTypeConstants.LIGHT_PURPLE}
        label={buttonNameConstants.PRINT_PRODUCT_LABEL}
        handleClick={handleViewCart}
      />
      <Row className="m-0">
        <Col md={6} className="payment-cart-wrapper container-scrollbar">
          <div className="payment-cart">
            <div className="">
              {cartItems?.map((cartItem, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center w-100 mt-1 "
                >
                  <div className="d-flex align-items-center gap-2">
                    <span className="opacity-75">
                      {cartItem?.price?.quantity} x
                    </span>
                    <span className="payment-cart-productname">
                      {cartItem.product_name}
                    </span>
                    <span className="opacity-50">
                      {!cartItem.sku.includes(
                        transactionConstants.QUICK_TRADE
                      ) &&
                        (cartItem.sku.includes(transactionConstants.BATCH_TRADE)
                          ? "Auto Generated"
                          : `#${getShortestSku(cartItem.sku)}`)}
                    </span>
                  </div>
                  <span className="payment-cart-productname">
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      getUnitPurchasePrice(newTradeData, cartItem)
                    )}
                  </span>
                </div>
              ))}
            </div>

            <hr />
            <div className="d-flex justify-content-between align-items-center w-100 mt-4">
              <div className="d-flex align-items-center gap-2">
                <span className="subtotal-heading">SALE TOTAL</span>
                <span>{calcullateItems()} Items</span>
              </div>
              <span className="payment-cart-productname">
                ${" "}
                {parseToThousandSeperatorDecimalNumber(
                  newTradeData?.totalAmountToPay
                )}
              </span>
            </div>
            {newTradeData &&
              newTradeData?.paymentHistory?.totalPaidAmount[
                transactionConstants.CASH
              ] > 0 && (
                <div className="d-flex w-100 justify-content-between align-items-center mt-3 pt-2 border-top">
                  <div className="d-flex align-items-center gap-2">
                    <span className="subtotal-heading">Cash</span>
                  </div>
                  <span className="payment-cart-productname">
                    $
                    {Number(
                      newTradeData?.paymentHistory?.totalPaidAmount[
                        transactionConstants.CASH
                      ]
                    ).toFixed(2)}
                  </span>
                </div>
              )}
            {newTradeData &&
              newTradeData?.paymentHistory?.totalPaidAmount[
                transactionConstants.STORE_CREDIT
              ] > 0 && (
                <div className="d-flex w-100 justify-content-between align-items-center mt-3 pt-2 border-top">
                  <div className="d-flex align-items-center gap-2">
                    <span className="subtotal-heading">Store Credit</span>
                  </div>
                  <span className="payment-cart-productname">
                    $
                    {Number(
                      newTradeData?.paymentHistory?.totalPaidAmount[
                        transactionConstants.STORE_CREDIT
                      ]
                    ).toFixed(2)}
                  </span>
                </div>
              )}
          </div>
        </Col>

        <Col md={6}>
          <div className="payment-div-wrapper">
            <Row className="m-0 w-100">
              {newTradeData?.paymentMethod ===
              transactionConstants.PAYMENT_COMPLETE ? (
                <Col md={12}>
                  <TradePaymentComplete
                    newTradeData={newTradeData}
                    handleClick={() => {}}
                    setNewTradeData={handleTradeData}
                    selectedCustomer={newTradeData?.customer}
                    handlePrintReceipt={handlePrintReceipt}
                    sendEmailOrSMS={sendEmailOrSMS}
                    handleShowDraftModal={handleShowDraftModal}
                    handleAddCustomerClick={handleAddCustomerClick}
                  />
                </Col>
              ) : (
                <>
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <span className="amount-to-pay-heading">Amount to Pay</span>
                    <div className="amount-to-paid-field-wrapper">
                      <span className="dollar-icon-alignment">$</span>
                      <input
                        disabled={
                          (newTradeData?.paymentHistory?.totalPaidAmount[
                            transactionConstants.CASH
                          ] || 0) +
                            (newTradeData?.paymentHistory?.totalPaidAmount[
                              transactionConstants.STORE_CREDIT
                            ] || 0) >
                          0
                            ? true
                            : false
                        }
                        // disabled={true}
                        type="number"
                        className="amount-to-paid-field"
                        value={newTradeData?.amountToPay}
                        step={0.01}
                        onChange={(e) => {
                          handleAmountToPayChange(e.target.value);
                        }}
                        onBlur={(e) => {
                          handleAmountToPayChange(
                            parseToDecimalNumber(e.target.value, true)
                          );
                        }}
                        max={
                          newTradeData?.totalAmountToPay -
                          getSumOfPaymentHistory(
                            newTradeData?.paymentHistory ||
                              tradeTransactionDefaultData.paymentHistory
                          )
                        }
                        min={1}
                        onFocus={handleEmptyOnFocus}
                      />
                    </div>
                  </Col>
                  <Col
                    md={12}
                    className="d-flex justify-content-between mt-3 align-items-center"
                  >
                    <span className="amount-to-pay-heading">
                      {/* {userPaymentType} */}

                      {userPaymentType === transactionConstants.STORE_CREDIT
                        ? buttonNameConstants.STORE_CREDIT
                        : buttonNameConstants.CASH}
                    </span>
                  </Col>

                  {userPaymentType !== globalConstants?.CASH &&
                    (newTradeData?.paymentHistory?.totalPaidAmount[
                      transactionConstants.CASH
                    ] > 0 ||
                      newTradeData?.paymentHistory?.totalPaidAmount[
                        transactionConstants.STORE_CREDIT
                      ]) > 0 &&
                    (newTradeData?.customer?.name !== "" &&
                    newTradeData?.customer?.name !== null ? (
                      <Col md={12} className="mt-3">
                        <SelectedCustomerCard
                          customer={newTradeData?.customer}
                          isStoreCredit={true}
                          isDeleteEditOptions={true}
                          handleCustomerDeleteClick={() => {
                            handleTradeData({
                              ...newTradeData,
                              PaymentType: globalConstants.CASH,
                              customer: { id: "", name: "" },
                            });
                          }}
                          handleAddCustomerClick={handleAddCustomerClick}
                          handleCustomerEditClick={handleCustomerEditClick}
                        />
                      </Col>
                    ) : (
                      <Col md={12} className="mt-4 mb-3">
                        <CustomerDropdown
                          handleChange={(selectedOption) => {
                            handleTradeData({
                              ...newTradeData,
                              customer: selectedOption?.value
                                ? parseJsonObject(selectedOption?.value)
                                : { id: "", name: "" },
                            });
                          }}
                          value={
                            newTradeData?.customer?.id
                              ? {
                                  value: stringifyObject(
                                    newTradeData?.customer
                                  ),
                                  label: (
                                    <div className="d-flex align-items-center gap-2 ">
                                      <span>
                                        <img
                                          src={CustomerImage}
                                          className="new-sale-customer-image"
                                          alt="customer"
                                        />
                                      </span>
                                      {`${newTradeData?.customer.firstName} ${newTradeData?.customer.lastName}`}
                                    </div>
                                  ),
                                }
                              : ""
                          }
                          isLockCustomer={true}
                          isGuestCustomers={true}
                          SelectIconImage={
                            categoryDropdownConstants.SEARCH_ICON
                          }
                          dropdownIndicator={false}
                          handleAddCustomerClick={handleAddCustomerClick}
                          selectedCustomerFlag={selectedCustomerFlag}
                        />
                      </Col>
                    ))}
                  <Col md={12} className="d-flex mt-3 ">
                    <div className="receiptTotal w-100">
                      <p className="receiptTotal-heading mb-0">Receipt Total</p>
                      <p className="receiptTotal-amount mb-0 mt-2">
                        ${" "}
                        {parseToThousandSeperatorDecimalNumber(
                          newTradeData?.totalAmountToPay
                        )}
                      </p>
                    </div>
                  </Col>
                  {userPaymentType === globalConstants.CASH ? (
                    ""
                  ) : (
                    <>
                      <Col md={12} className="d-flex mt-4 mb-3">
                        <input
                          className="checkbox-field"
                          type="checkbox"
                          checked={isConsent}
                          onChange={() => setIsConsent(!isConsent)}
                        />
                        <span className="checkbox-field-text ms-1">
                          Customer read and agree with terms of trade credit
                        </span>
                      </Col>
                    </>
                  )}

                  <Col md={12} className="d-flex mt-3 align-items-end gap-2">
                    <Button
                      label={buttonNameConstants.BACK}
                      className="w-100"
                      type="button"
                      handleClick={() => getBackNavigation()}
                      buttonType={buttonTypeConstants.GHOST_BUTTON}
                      isDisabled={getSumOfPaymentHistory(
                        newTradeData?.paymentHistory
                      )}
                    />
                    <Button
                      label={buttonNameConstants.COMPLETE}
                      className="w-100"
                      type="button"
                      handleClick={handlePayment}
                      isDisabled={isSpinnerEnabled(spinnerArray, [
                        transactionConstants.MAKE_TRADE_TRANSACTION,
                        transactionConstants.GET_TRADE_ID,
                      ])}
                    />
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Col>

        <TradePrintLabelModal
          printLabelModal={printLabelModal}
          togglePrintLabelModal={togglePrintLabelModal}
          selectedRows={selectedRows}
        />
        {addCustomerModal && (
          <AddNewCustomerModal
            selectedCustomer={selectedCustomer}
            addCustomerModal={addCustomerModal}
            customerToEdit={customerToEdit}
          />
        )}
      </Row>
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerArray: state.system.spinnerArray,
  currentStore: state.store.currentStore,
  transaction: state.transaction,
  customer: state.customer,
  user: state.authentication.user,
  printer: state.printer,
  isAddCustomerModal: state.transaction.isAddCustomerModal,
  userNotifications: state.store.userNotifications,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setNewTradeData: (data, storeId) =>
    dispatch(transactionActions.setNewTradeData(data, storeId)),
  newTradeTransaction: (
    data,
    transactionObject,
    storeId,
    companyId,
    handleOpenCashDrawer,
    defaultReceiptPrinter
  ) =>
    dispatch(
      transactionActions.newTradeTransaction(
        data,
        transactionObject,
        storeId,
        companyId,
        handleOpenCashDrawer,
        defaultReceiptPrinter
      )
    ),
  toggleAddCustomerModal: (value) =>
    dispatch(transactionActions.toggleAddCustomerModal(value)),
  sendEmailOrSMS: (data) => dispatch(transactionActions.sendEmailOrSMS(data)),
  handleDraftModal: (isToggle) =>
    dispatch({
      type: transactionConstants.SET_DRAFT_CONFIRMATION,
      data: isToggle,
    }),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
});

//-------Export transaction Component
export default connect(mapStateToProps, mapDispatchToProps)(NewTradePayment);
