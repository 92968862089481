import {
  EMPTY_STRING,
  globalConstants,
  inventoryConstants,
  transactionConstants,
} from "../../constants";
import { customerService } from "../../services";
import {
  calculateRatio,
  parseToDecimalNumber,
  getSumOfPaymentHistory,
} from "../utility";

// lock or unlock the trade or cash offer
export const handleLockOrUnlockTradeItem = (
  currentStore,
  tradeData,
  setTradeData,
  itemId,
  isCartItem = false
) => {
  // Create a copy of tradeData to avoid direct mutation
  const updatedTradeData = [...(isCartItem ? tradeData?.inventory : tradeData)];

  // Find the item in the tradeData by its itemId
  const itemIndex = updatedTradeData.findIndex((item) => item.id === itemId);
  if (itemIndex !== -1) {
    // Toggle the lock status of the item
    const item = updatedTradeData[itemIndex];

    if (isCartItem) {
      item.tradeProductMetaData.isItemLocked =
        !item.tradeProductMetaData.isItemLocked;

      setTradeData(
        {
          ...tradeData,
          inventory: updatedTradeData,
        },
        currentStore?.id
      );
    } else {
      item.isItemLocked = !item.isItemLocked;
      // Update the tradeData with the new state
      setTradeData(updatedTradeData, currentStore?.id);
    }
  }
};

//------- get Total of CashOffer and Trade Offer
//------- in batch and trade cart
export const getTotalTradeOfferOfBatchProducts = (
  addTradeBatchInventory,
  isTradeCart = false,
  isCountLockedItem = true
) => {
  let totalCashOffer = 0;
  let totalTradeOffer = 0;

  addTradeBatchInventory.forEach((currentInv) => {
    const isItemLocked = isTradeCart
      ? currentInv.tradeProductMetaData.isItemLocked
      : currentInv.isItemLocked;
    const quantity = isTradeCart
      ? currentInv.price.quantity
      : currentInv.inStockQuantity;
    // Determine whether to count this item or not based on isCountLockedItem and currentInv.isItemLocked
    const shouldCount = isCountLockedItem
      ? !isItemLocked // Only count the item if it's not locked
      : true; // Count all items if isCountLockedItem is false

    // If the item should be counted, add its value to the total
    if (shouldCount) {
      totalCashOffer += Number(currentInv.cashOffer * quantity);
      totalTradeOffer += Number(currentInv.tradeOffer * quantity);
    }
  });

  return {
    cashOfferValue: parseToDecimalNumber(totalCashOffer),
    tradeOfferValue: parseToDecimalNumber(totalTradeOffer),
  };
};

//-------- calculate percentage of cart item for both cash and trade
export const handleItemCashTradePercentage = (
  inventory,
  isTradeCart = false,
  isLockedItemIncluded = true
) => {
  // Calculate the total cash offer
  const totalCashOffer =
    getTotalTradeOfferOfBatchProducts(
      inventory,
      isTradeCart,
      isLockedItemIncluded
    ).cashOfferValue ?? 0;

  // Calculate the total trade offer
  const totalTradeOffer =
    getTotalTradeOfferOfBatchProducts(
      inventory,
      isTradeCart,
      isLockedItemIncluded
    ).tradeOfferValue ?? 0;

  // Map through each inventory item to calculate and set both cash and trade percentages
  const updatedInventory = inventory.map((item) => {
    const quantity = isTradeCart ? item.price.quantity : item.inStockQuantity;
    // Only calculate percentages for items that are not locked
    if (!item.isItemLocked) {
      const itemTotalCashOffer = Number(item.cashOffer * quantity);
      const cashPercentagePerItem =
        totalCashOffer > 0 ? (itemTotalCashOffer / totalCashOffer) * 100 : 0;

      const itemTotalTradeOffer = Number(item.tradeOffer * quantity);
      const tradePercentagePerItem =
        totalTradeOffer > 0 ? (itemTotalTradeOffer / totalTradeOffer) * 100 : 0;

      if (isTradeCart) {
        return {
          ...item,
          tradeProductMetaData: {
            ...item.tradeProductMetaData,
            cashPercentagePerItem: parseToDecimalNumber(cashPercentagePerItem),
            tradePercentagePerItem: parseToDecimalNumber(
              tradePercentagePerItem
            ),
          },
        };
      } else {
        return {
          ...item,
          cashPercentagePerItem: parseToDecimalNumber(cashPercentagePerItem),
          tradePercentagePerItem: parseToDecimalNumber(tradePercentagePerItem),
        };
      }
    }

    // If the item is locked, return it without modifications
    return item;
  });

  return updatedInventory;
};

//-------get updated tardeOffer and CashOffer Value
export const getUpdatedOfferValues = (
  inputValue,
  isLink = true,
  isCashOfferValue,
  isTradeCart = true,
  updatedCartInventory,
  isLockedItemIncluded = true
) => {
  let recalulatePercentageForItems = handleItemCashTradePercentage(
    updatedCartInventory,
    isTradeCart,
    isLockedItemIncluded
  );

  // Step 6: Update the batch inventory based on whether it's a cash offer or trade offer
  let finalUpdatedBatchInventory = recalulatePercentageForItems.map((item) => {
    // Skip locked items (no need to update their offer values)
    if (item.tradeProductMetaData.isItemLocked) {
      return item; // Return unchanged item for locked items
    }

    let offerValue = 0;
    // Default opposite offer value
    let oppositeOfferValue = !isCashOfferValue
      ? item.cashOffer
      : item.tradeOffer;

    if (isCashOfferValue) {
      // Calculate the cash offer for the item
      offerValue =
        (inputValue * item.tradeProductMetaData.cashPercentagePerItem) /
        100 /
        item.price.quantity;

      // Apply the minimum threshold for cashOffer (if it falls below the threshold, set it to the default value)
      offerValue = parseToDecimalNumber(
        offerValue <= inventoryConstants.DEFAULT_OFFER_VALUE
          ? inventoryConstants.DEFAULT_OFFER_VALUE
          : offerValue
      );

      if (isLink) {
        // Calculate the corresponding trade offer based on the item ratio if isLink is true
        const itemRatio = calculateRatio(item, true);
        oppositeOfferValue = parseToDecimalNumber(
          itemRatio * parseToDecimalNumber(offerValue)
        );
      }
    } else {
      // Calculate the trade offer for the item
      offerValue =
        (inputValue * item.tradeProductMetaData.tradePercentagePerItem) /
        100 /
        item.price.quantity;
      // Apply the minimum threshold for tradeOffer
      offerValue = parseToDecimalNumber(
        offerValue <= inventoryConstants.DEFAULT_OFFER_VALUE
          ? inventoryConstants.DEFAULT_OFFER_VALUE
          : offerValue
      );
      if (isLink) {
        // Calculate the corresponding cash offer based on the item ratio if isLink is true
        const itemRatio = calculateRatio(item, true);
        oppositeOfferValue = parseToDecimalNumber(offerValue / itemRatio);
      }
    }
    return {
      ...item,
      cashOffer: isCashOfferValue
        ? parseToDecimalNumber(offerValue)
        : oppositeOfferValue,
      tradeOffer: isCashOfferValue
        ? parseToDecimalNumber(oppositeOfferValue)
        : offerValue,
    };
  });

  return finalUpdatedBatchInventory;
};

//------- offer values after slit payment
export const getSplitPaymentOfferValues = (
  inventory,
  remainingAmountToPay,
  newTradeData,
  response
) => {
  const paymentStatus =
    response.totalAmountToPay - getSumOfPaymentHistory(response.paymentHistory)
      ? globalConstants.EMPTY_STRING
      : transactionConstants.PAYMENT_COMPLETE;

  const isCashOfferValue = response.PaymentType === globalConstants.CASH;

  if (paymentStatus === globalConstants.EMPTY_STRING) {
    let reCalculatePercentages = handleItemCashTradePercentage(
      inventory,
      true,
      false
    );

    reCalculatePercentages = reCalculatePercentages.map((item) => {
      let offerValue = 0;
      let oppositeOfferValue = 0;

      // Calculate the cash offer for the item
      offerValue =
        (Number(response.currentAmountToPay) *
          Number(item.tradeProductMetaData.cashPercentagePerItem)) /
        100 /
        Number(item.price.quantity);

      // Apply the minimum threshold for cashOffer (if it falls below the threshold, set it to the default value)
      offerValue = parseToDecimalNumber(
        offerValue <= inventoryConstants.DEFAULT_OFFER_VALUE
          ? inventoryConstants.DEFAULT_OFFER_VALUE
          : offerValue
      );

      oppositeOfferValue =
        (Number(remainingAmountToPay) *
          Number(item.tradeProductMetaData.tradePercentagePerItem)) /
        100 /
        Number(item.price.quantity);

      // Apply the minimum threshold for cashOffer (if it falls below the threshold, set it to the default value)
      oppositeOfferValue = parseToDecimalNumber(
        oppositeOfferValue <= inventoryConstants.DEFAULT_OFFER_VALUE
          ? inventoryConstants.DEFAULT_OFFER_VALUE
          : oppositeOfferValue
      );

      return {
        ...item,
        cashOffer: isCashOfferValue
          ? parseToDecimalNumber(offerValue)
          : oppositeOfferValue,
        tradeOffer: isCashOfferValue
          ? parseToDecimalNumber(oppositeOfferValue)
          : offerValue,
      };
    });

    return reCalculatePercentages;
  }
  return inventory;
};

export const getLockCustomerPayload = (customer, currentStore, isLock) => {
  return {
    id: customer.id,
    companyId: currentStore.companyId,
    store: {
      id: currentStore.id,
      name: currentStore.storeName,
    },
    isCustomerInUseOfTrade: isLock,
  };
};

// if user is parking the transaction with guestCustomer => 1. Unlock guest customer 2. Remove customer from transaction 3. Set paymentType to cash (as if storeCredit then customer is required)
export const handleRemoveGuestCustomerBeforeTradeDraft = (
  transactionData,
  currentStore
) => {
  let updatedTransactionCustomer = {};
  let paymentType = transactionData.PaymentType;
  if (!transactionData.customer.id) {
    updatedTransactionCustomer = { id: EMPTY_STRING, name: EMPTY_STRING };
  } else if (transactionData.customer.isGuestCustomer) {
    updatedTransactionCustomer = { id: EMPTY_STRING, name: EMPTY_STRING };
    paymentType = globalConstants.CASH;
    customerService.lockCustomer(
      getLockCustomerPayload(transactionData.customer, currentStore, false)
    );
  } else {
    updatedTransactionCustomer = transactionData.customer;
  }

  return { customer: updatedTransactionCustomer, PaymentType: paymentType };
};

export const getSellTradeCustomerObject = (customer) => {
  return customer
    ? {
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        mobile: customer.mobile,
        companyCustomerId: customer.companyCustomerId,
        currentBalance: customer.currentBalance,
        store: {
          id: customer.store.id,
          name: customer.store.storeName,
        },
        id: customer.id,
        name: `${customer.firstName} ${customer.lastName}`,
        isGuestCustomer: customer.isGuestCustomer ? true : false,
      }
    : EMPTY_STRING;
};
