import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Topbar from "../../../shared/components/topbar/Topbar";

import moment from "moment";
import { PRODUCT_TYPES_ENUMS } from "../../../system/globalEnums";
import SearchField from "../../../shared/components/searchField/SearchField";
import TableCustomHeader from "../../../shared/components/table/TableCustomHeader";
import CategoriesDropdown from "../../../shared/components/selectDropdown/CategoriesDropdown";
import DashboardDatePicker from "../../../shared/components/datetimePickerDropdown/DashboardDatePicker";
import {
  CrossIcon,
  SearchIcon,
  BlueBoxIcon,
  ExportCSVIcon,
} from "../../../assets/icons/iconsProvider";
import {
  globalConstants,
  inventoryConstants,
  buttonNameConstants,
  categoryDropdownConstants,
  defaultGridState,
} from "../../../constants";
import { inventoryActions } from "../../../redux/actions";
import {
  formatDate,
  downloadCSV,
  getDefaultFilterType,
  getCustomTypesOptionList,
  getCustomCategoriesOptionList,
  LoadingIndicator,
  isSpinnerEnabled,
  prepareFilterForInventoryHistoryPaginationCall,
  formatTime,
  isButtonDisable,
  getReceiptPrintDate,
  getShortestSku,
  parseToThousandSeperatorDecimalNumber,
  parseToThousandSeperatorWholeNumber,
  getRowId,
  getRowStyle,
} from "../../../shared/utility";
import CustomGridHeader from "../../../shared/components/agGrid/CustomGridHeader";
import { useDebouncedCallback } from "use-debounce";
import AGGridToolTip from "../../../shared/components/toolTipComponent/AGGridToolTip";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import ServerPaginationTable from "../../../shared/components/agGrid/ServerPaginationTable";
import FilterDropdowns from "../../../shared/components/FilterDropdowns/FilterDropdowns";

const History = (props) => {
  const {
    inventory,
    currentStore,
    itemOrganization,
    spinnerArray,
    getPaginatedInventoryHistory,
  } = props;

  const { inventoryHistory } = inventory;
  //-------UseRef
  const gridRef = useRef();

  //-------Reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;

  const [typeSearchText, setTypeSearchText] = useState("");
  const [gridState, setGridState] = useState(defaultGridState);
  const [isTypeSearchLoading, setIsTypeSearchLoading] = useState(false);
  const [debouncedTypeSearchText, setDebouncedTypeSearchText] = useState("");
  const [timeFrame, setTimeFrame] = useState({
    start: "",
    end: "",
    duration: "daily",
  });
  const [transactionType, setTransactionType] = useState({
    label: globalConstants.ALL,
    value: globalConstants.ALL,
  });
  const [category, setCategory] = useState("");
  const [productType, setProductType] = useState(
    getDefaultFilterType(customTypes, PRODUCT_TYPES_ENUMS.VIDEO_GAME)
  );
  const [productSubcategory, setProductSubCategory] = useState("");

  const skeletonArray = Array(10).fill({});
  const updateGridState = (gridState) => {
    getPaginatedInventoryHistory(currentStore?.id, gridState);
    setGridState(gridState);
  };

  const handleDebouncedTypeSearch = useDebouncedCallback((value) => {
    setTypeSearchText(value);
    updateGridState({ ...gridState, searchQuery: value });
  }, 1000);

  const handleTypeSearchChange = (value) => {
    setIsTypeSearchLoading(true);
    setDebouncedTypeSearchText(value);
    handleDebouncedTypeSearch(value);
  };

  const filterColumnforExport = (allData) => {
    return allData.map((row) => {
      const exportObject = {
        category: row?.product?.category,
        quantity: row?.quantity,
        processedBy: row?.processedBy,
        date: row?.createdOn,
        transactionType: row?.TransactionType,
      };
      return exportObject;
    });
  };
  const getTimeFrame = (value) => {
    setTimeFrame({
      ...timeFrame,
      start: moment.utc(value.start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      end: moment.utc(value.end).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      duration: value?.duration,
    });
  };

  const handleExportButtonClick = () => {
    downloadCSV(
      filterColumnforExport(inventoryHistory),
      inventoryConstants.EXPORT_FILE_NAME
    );
  };

  const HistoryColDefs = useMemo(
    () => [
      {
        headerName: "Date",
        field: "createdOn",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.createdOn ? (
            <span className="inventory-cell-max-size ">
              <TableCustomHeader
                id={`created-on-in-array-${params?.data?.id}`}
                label={
                  <span className="inventory-cell-max-size">
                    {getReceiptPrintDate(params.data.createdOn)}
                  </span>
                }
                tooltopText={
                  <p className="text-start fw-normal m-0">
                    {getReceiptPrintDate(params.data.createdOn)}
                  </p>
                }
                className="sku-tooltip"
              />
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },

      {
        headerName: "Activity",
        field: "TransactionType",
        cellRenderer: (params) =>
          params?.data?.TransactionType ? (
            `${params.data.TransactionType}`
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Name",
        field: "product.name",
        cellRenderer: (params) =>
          params?.data?.product?.name ? (
            <span className="inventory-cell-max-size ">
              <TableCustomHeader
                id={`product-name-in-array-${params.data.id}`}
                label={
                  <span className="inventory-cell-max-size">
                    {params.data.product.name}
                  </span>
                }
                tooltopText={
                  <p className="text-start fw-normal m-0">
                    {params.data.product.name}
                  </p>
                }
                className="sku-tooltip"
              />
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),

        cellStyle: {
          overflow: "visible",
        },
      },
      {
        headerName: "Type",
        field: "product.productType",
        cellRenderer: (row) => {
          return row?.data?.product?.productType ? (
            row.data.product.productType
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          );
        },
      },
      {
        headerName: "Category",
        field: "product.category",
        cellRenderer: (params) =>
          params?.data?.product?.category ? (
            <span className="inventory-cell-max-size">
              {params.data.product.category}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerTooltip: "Unit Purchase Price",
        headerName: "Unit P...",
        field: "cogs",
        cellRenderer: (params) =>
          params?.data?.cogs || params?.data?.cogs === 0 ? (
            `$${parseToThousandSeperatorDecimalNumber(params.data.cogs)}`
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerTooltip: "Unit Sell Price",
        headerName: "Unit S...",
        field: "amount",
        cellRenderer: (params) =>
          params?.data?.amount || params?.data?.amount === 0 ? (
            `$${parseToThousandSeperatorDecimalNumber(params.data.amount)}`
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Quantity",
        field: "quantity",
        cellRenderer: (params) =>
          params?.data?.quantity || params?.data?.quantity === 0 ? (
            `${parseToThousandSeperatorWholeNumber(params.data.quantity)}`
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerTooltip: "Processed By",
        headerName: "Proces...",
        field: "processedBy",
        cellRenderer: (params) =>
          params?.data?.processedBy ? (
            `${params.data.processedBy}`
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
    ],
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (currentStore && timeFrame.start && timeFrame.end) {
      updateGridState({
        ...gridState,
        filters: prepareFilterForInventoryHistoryPaginationCall(
          productType,
          category,
          transactionType,
          timeFrame.start,
          timeFrame.end,
          productSubcategory
        ),
      });
    }
  }, [
    timeFrame,
    currentStore,
    transactionType,
    category,
    productType,
    productSubcategory,
  ]);
  const clearSearchText = () => {
    setTypeSearchText("");
    setDebouncedTypeSearchText("");
  };
  const gridData = useMemo(() => {
    let filteredData = inventoryHistory?.inventoryHistory || [];
    setGridState({
      ...gridState,
      pageIndex: inventoryHistory.pageNumber || defaultGridState?.pageIndex,
      totalRecords:
        inventoryHistory.totalRecords || defaultGridState?.totalRecords,
      pageEndRecordNumber:
        inventoryHistory.pageEndRecordNumber ||
        defaultGridState?.pageEndRecordNumber,
      pageStartRecordNumber:
        inventoryHistory.pageStartRecordNumber |
        defaultGridState?.pageStartRecordNumber,
    });
    setIsTypeSearchLoading(false);
    return filteredData;
  }, [inventory.inventoryHistory]);

  useEffect(() => {
    setProductType(getDefaultFilterType(customTypes, PRODUCT_TYPES_ENUMS.ALL));
  }, [customTypes]);

  return (
    <>
      <Topbar
        exportButton={true}
        title="Inventory History"
        IconImage={ExportCSVIcon}
        label={buttonNameConstants.EXPORT_CSV}
        handleClick={() => handleExportButtonClick()}
      ></Topbar>
      <Row className="m-0">
        <Col
          md={12}
          className="d-flex justify-conetent-center align-items-center gap-2"
        >
          <div className={`w-100 d-flex align-items-center gap-3 `}>
            <DashboardDatePicker label="Date" getTimeFrame={getTimeFrame} />{" "}
            <CategoriesDropdown
              label="Activity Type"
              isClearable={false}
              placeholder="Activity Type"
              noOptionsMessage="No Activity Type Found"
              options={inventoryConstants.ACTIVITY_TYPE}
              className={"filter-dropdowns-activity-type "}
              SelectIconImage={categoryDropdownConstants.BEND_ICON}
              handleChange={(e) => {
                setTransactionType(
                  e == null
                    ? {
                        label: globalConstants.ALL,
                        value: globalConstants.ALL,
                      }
                    : e
                );
              }}
              defaultValue={{
                value: PRODUCT_TYPES_ENUMS.ALL,
                label: "All",
              }}
            />
          </div>
        </Col>
        <Col
          md={12}
          className="d-flex justify-conetent-center align-items-center gap-2"
        >
          <div className={`w-100 d-flex align-items-center gap-3`}>
            <SearchField
              value={debouncedTypeSearchText}
              label="Search"
              placeHolder="Type or Scan"
              IconImage={SearchIcon}
              BlueBoxIcon={BlueBoxIcon}
              clearSearchText={clearSearchText}
              CrossIcon={typeSearchText ? CrossIcon : ""}
              onChange={(e) => handleTypeSearchChange(e.target.value)}
              LoadingIndicator={isTypeSearchLoading && LoadingIndicator}
            />
            <FilterDropdowns
              customTypes={customTypes}
              customCategories={customCategories}
              customSubCategories={customSubCategories}
              setSearchProductType={setProductType}
              setSearchProductCategory={setCategory}
              setSearchProductSubCategory={setProductSubCategory}
              defaultProductType={PRODUCT_TYPES_ENUMS.ALL}
              isDisabledAll={isButtonDisable(
                spinnerArray,
                inventoryConstants.GET_ALL_INVENTORY
              )}
            />
          </div>
        </Col>
      </Row>
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3 p-0">
        <ServerPaginationTable
          colDefs={HistoryColDefs}
          gridRef={gridRef}
          gridData={
            isSpinnerEnabled(
              spinnerArray,
              inventoryConstants.GET_INVENTORY_HISTORY
            )
              ? skeletonArray
              : gridData
          }
          gridState={gridState}
          getRowId={getRowId}
          getRowStyle={getRowStyle}
          updateGridState={updateGridState}
          paginationPageSize={gridState.pageSize}
          pageSizeOptions={gridState.pageSizeOptions}
          isDisablePaginationActions={isSpinnerEnabled(
            spinnerArray,
            inventoryConstants.GET_INVENTORY_HISTORY
          )}
          tableHeight={`calc(100vh - 290px)`}
        />
      </Row>
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  itemOrganization: state.itemOrganization,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getPaginatedInventoryHistory: (storeId, gridState) =>
    dispatch(inventoryActions.getPaginatedInventoryHistory(storeId, gridState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
